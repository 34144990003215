import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import { Layout as AntLayout, Menu, theme, Typography, } from 'antd';

import {
    DesktopOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Divider ,Switch } from 'antd';

const { Text } = Typography;

const { Header, Content, Footer, Sider } = AntLayout;


function getItem(label, key, icon, children = null, url = null) {
    return {
      key,
      icon,
      children,
      label: url ? <Link to={url} style={{ color: 'lightgray' }}>{label}</Link> : label,
    };
  }
  
  const items = [
    getItem('應用程式', '1', <PieChartOutlined style={{ color: '#FFFFFF' }} />, null, '/myapp'),
    getItem('掃描及階段作業', '2', <DesktopOutlined style={{ color: '#FFFFFF' }} />, null, '/scan'),
    getItem('程式庫', '3', <PieChartOutlined style={{ color: '#FFFFFF' }} />, null, '/osLibraries'),
    getItem('儀錶板', '4', <DesktopOutlined style={{ color: '#FFFFFF' }} />, null, '/dashboard'),
    getItem('工具', 'tool', <UserOutlined style={{ color: '#FFFFFF' }} />, [
      getItem('整合', 'tool1', null, null, '/plugins'),
      getItem('Presence', 'tool2', null, null, '/presences'),
      getItem('API', 'tool3', null, null, '/apikey'),
    ], { color: '#FFFFFF' }),
    getItem('組織', 'team', <TeamOutlined style={{ color: '#FFFFFF' }} />, [
      getItem('原則', 'team1', null, null, '/policies'),
      getItem('網域', 'team2', null, null, '/domains'),
      getItem('訂閱', 'team3', null, null, '/subscriptions'),
      getItem('審核追蹤', 'team4', null, null, '/audit'),
    ], { color: '#FFFFFF' }),
  ];
  

const items2 = [
  {
    label: <Link to="/plugins" style={{ color: '#FFFFFF' }}>外掛程式及API</Link>,
    key: 'mail',
    icon: <MailOutlined style={{ color: '#FFFFFF' }} />,
  },
  {
    label: <a href="https://help.hcl-software.com/appscan/ASoC/index.html" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF' }}>說明文件</a>,
    key: 'mail',
    icon: <MailOutlined style={{ color: '#FFFFFF' }} />,
  },  
  {
    label: <span style={{ color: '#FFFFFF' }}>Support</span>,
    icon: <SettingOutlined style={{ color: '#FFFFFF' }} />,
    children: [
      {
        type: 'group',
        children: [
          {
            label: <Link to="/support/customer-support" style={{ color: '#FFFFFF' }}>Customer Support</Link>,
            key: 'setting:1',
          },
          {
            label: <Link to="/support/appscan-resources" style={{ color: '#FFFFFF' }}>AppScan Resources</Link>,
            key: 'setting:2',
          },
        ],
      },
    ]
  },
  {
    label: <span style={{ color: '#FFFFFF' }}>Language</span>,
    icon: <SettingOutlined style={{ color: '#FFFFFF' }}/>,
    children: [
      {
        type: 'group',
        children: [
          {
            label: <Link to="/language/english" style={{ color: '#272727', backgroundColor: '#FFFFFF' }}>English</Link>,
            key: 'setting:3',
          },
          // {
          //   label: <Link to="/language/simplified-chinese" style={{ color: '#FFFFFF' }}>Simplified Chinese</Link>,
          //   key: 'setting:4',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Traditional Chinese</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>French (Français)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>German (Deutsch)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Italian (Italiano)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Japanese (日本語)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Korean (한국어)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Portuguese (Português)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Russian (Русский)</Link>,
          //   key: 'setting:5',
          // },
          // {
          //   label: <Link to="/language/traditional-chinese" style={{ color: '#FFFFFF' }}>Spanish (Español)</Link>,
          //   key: 'setting:5',
          // },          
          
        ],
      },
    ],
  },
  {
    key: 'alipay',
    label: (
      <Link to="/"  style={{ color: '#FFFFFF' }}>
        登出
      </Link>
    ),
  },
];

function LayoutComponent({ children }) {
    const [collapsed, setCollapsed] = useState(false);
    const {
      token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
  
    const [current, setCurrent] = useState('');
    const handleMenuClick = (e) => {
      console.log('click ', e);
      setCurrent(e.key);
    };

    const [selectedKeys, setSelectedKeys] = useState([]); // 初始無選中項目

    const handleClick = (e) => {
      setSelectedKeys([e.key]); // 設置選中的鍵值，使其反白
    };

    const [isHovered, setIsHovered] = useState(false); //footer的選項 說明文件
    const [isHovered2, setIsHovered2] = useState(false); //footer的選項 隱私權原則 
    const [isHovered3, setIsHovered3] = useState(false); //footer的選項 支援
    const [isHovered4, setIsHovered4] = useState(false); //footer的選項 指導教學


  return (
    <div>
      <Helmet>
        <title>IBM</title>
        <meta name="description" content="This is a IBM website." />
        <meta name="keywords" content="React Web Development" />
        {/* <link rel="icon" href="/favicon.ico" /> */}
      </Helmet>
      <AntLayout>
        <Header
          style={{
            background: '#272727',
            height: '48px',
            padding: '0 16px',
            width: '100%',
            position: 'fixed',
            zIndex: 1000,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Menu
              onClick={handleMenuClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items2}
              defaultOpenKeys={['language', 'team']} // 預設展開的子選單
              style={{
                lineHeight: '48px',
                backgroundColor: '#272727',
              }}
            />
          </div>
        </Header>

        <AntLayout style={{ marginTop: '48px' }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            // breakpoint="md"
            style={{
              background: '#272727',
              height: 'calc(100vh - 48px)',
              position: 'fixed',
              top: '48px',
              left: 0,
              width: collapsed ? '80px' : '200px',
              zIndex: 100,
              // overflow: 'auto',
              
            }}
          >
              <Menu
                mode="inline"
                items={items}
                defaultOpenKeys={['tool', 'team']} // 預設展開的子選單
                selectedKeys={selectedKeys} // 使用 selectedKeys 控制選中項目
                onClick={handleClick} // 點擊時更新選中項目
                style={{
                  background: '#272727',
                  color: '#fff',
                  padding: '16px',
                }}/>
          </Sider>

          <AntLayout
            style={{
              marginLeft: collapsed ? '80px' : '200px',
              transition: 'margin-left 0.3s ease',
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // 讓內容區域水平置中
            }}
          >
          <Content
            style={{
              flex: 1,
              padding: '0 16px',
              backgroundColor: 'transparent',
              maxWidth: '100%',
              width: '100%',
              boxSizing: 'border-box',
              overflowX: 'auto', // 只允許橫向滾動
              // overflowY: 'hidden', // 隱藏垂直滾動
            }}
          >
            {children}
          </Content>

            <Footer
              style={{
                textAlign: 'center',
                padding: '10px 0',
                position: 'relative',
                bottom: 0,
                width: '100%',
              }}
            >
              
              <hr style={{border: "none", height: "1px", background:"#E0E0E0" }} />
              <br/>
              <div style={{fontSize:"14px"}}>
              <a
                href="https://help.hcl-software.com/appscan/ASoC/index.html"
                target='blank'
                style={{
                  color: "#3C3C3C",
                  textDecoration: isHovered ? "underline" : "none",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                說明文件
              </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://www.hcl-software.com/legal/privacy"
                target='blank'
                style={{
                  color: "#3C3C3C",
                  textDecoration: isHovered2 ? "underline" : "none",
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                隱私權原則 
              </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                href="https://support.hcl-software.com/csm"
                target='blank'
                style={{
                  color: "#3C3C3C",
                  textDecoration: isHovered3 ? "underline" : "none",
                }}
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
              >
                支援
              </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                href="https://www.youtube.com/channel/UC07b9GB8a-4c6-T6pd2bbBQ/search?query=appscan+on+cloud"
                target='blank'
                style={{
                  color: "#3C3C3C",
                  textDecoration: isHovered4 ? "underline" : "none",
                }}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
              >
                指導教學
              </a>
              </div>
              <br/>
            </Footer>
          </AntLayout>
        </AntLayout>
      </AntLayout>


    </div>
  );
}

export default LayoutComponent;


