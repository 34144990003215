import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

function Login() {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/'); // 指定跳轉的路由
    };

    return (
        <div style={{ 
            backgroundColor: '#F2F2F2', 
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                width: '400px',
                border: '1px solid #DCDCDC',
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '30px',
                transition: 'height 0.3s ease', // 平滑高度變化
            }}>
                
                <Form
                    name="basic"
                    layout="vertical"
                    style={{
                        width: '100%',
                        maxWidth: '340px'
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <h1 style={{ textAlign: 'center' }}><strong>Software</strong></h1>
                    <hr/>

                    <h3 style={{ textAlign: 'center' }}><strong>重設密碼</strong></h3>
                    <Form.Item
                        label="電子郵件或使用者名稱 "
                        name="mailOrusername"
                        required
                        rules={[
                            {
                                required: true,
                                message: '請輸入電子郵件或使用者名稱 ',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <h5>只有設定行動電話號碼后，才能使用 簡訊或語音通話。</h5>

                    <Form.Item style={{ textAlign: 'center' }}>
                         <Button style={{height:'50px',width:'330px',background:'#0066FF',color: '#FFFFFF'}}  htmlType="submit" onClick={handleLogin}>
                            透過簡訊重設
                        </Button>
                        <br/><br/>
                        <Button style={{height:'50px',width:'330px',background:'#0066FF',color: '#FFFFFF'}}  htmlType="submit" onClick={handleLogin}>
                            透過語音通話重設
                        </Button>
                        <br/><br/>
                        <Button style={{height:'50px',width:'330px',background:'#0066FF',color: '#FFFFFF'}}  htmlType="submit" onClick={handleLogin}>
                            透過電子郵件重設
                        </Button>
                        <br/><br/>
                    </Form.Item>
                </Form>
 
                <Button style={{border:'none',boxShadow:'none', textDecoration: 'underline' }} onClick={handleLogin}>
                    返回登入
                </Button>
            </div>
        </div>
    );
}

export default Login;
