// pages/About.js
import { Link } from 'react-router-dom';
import React from 'react';
import Layout from '../Layout';
import '../App.css';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex, ConfigProvider,Calendar,Row, Col,Pagination,message, Upload,DatePicker,Card,Empty,Progress   } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,DownloadOutlined,CalendarOutlined,FilterOutlined,InboxOutlined,InfoCircleOutlined,CheckCircleOutlined,CloseCircleOutlined,BarChartOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
const { Text } = Typography;




//日期設定
const { RangePicker } = DatePicker;
const onChange = (date) => {
  if (date) {
    console.log('Date: ', date);
  } else {
    console.log('Clear');
  }
};
const onRangeChange = (dates, dateStrings) => {
  if (dates) {
    console.log('From: ', dates[0], ', to: ', dates[1]);
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
  } else {
    console.log('Clear');
  }
};
const rangePresets = [
  {
    label: '前3個月',
    value: [dayjs().subtract(3, 'month'), dayjs()],
  },
  {
    label: '前7天',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: '前30天',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: '前12個月',
    value: [dayjs().subtract(12, 'month'), dayjs()],
  },

];


//業務單位：下拉是選單的選項
const handleChange = (value) => {
  console.log(`selected ${value}`);
};


//作用中的問題 旁邊小問號圈圈對話框裡的內容
const contentWork = (
  <div style={{}}>
    <p>作用中的問題是指狀態為「新建」（已刪除）、「待解決」、「處理中」或「已重新開啟」的問題。</p>
    <p>趨勢：所選時段結束時的資料與開始時的資料相比。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/ent_issue_class_settings.html" target='_blank'>進一步瞭解</a>
  </div>
);

//MTTR 問題 旁邊小問號圈圈對話框裡的內容
const contentWork2 = (
  <div style={{}}>
    <p>解決問題的平均時間是指在選定時段內解決問題所需的平均時間，僅計算標記為已修復的問題。</p>
    <p>趨勢：所選期間與前一期間相比的值。</p>
  </div>
);

//掃描 旁邊小問號圈圈對話框裡的內容
const contentWork3 = (
  <div style={{}}>
    <p>Includes executions and deleted scans</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/ent_dashboard_charts.html" target='_blank'>進一步瞭解</a>
  </div>
);

//中間的問題區塊 作用中的問題 旁邊小問號圈圈對話框裡的內容
const contentWork4 = (
  <div style={{}}>
    <p>依照嚴重性顯示作用中的問題隨時間變化的情況。</p>
  </div>
);

//中間的問題區塊 解決問題的平均時間 旁邊小問號圈圈對話框裡的內容
const contentWork5 = (
  <div style={{}}>
    <p>Refers to the average time taken in days to resolve issues, categorized by severity, within the selected time period. It only includes issues that were marked as fixed or passed.</p>
  </div>
);

//中間的應用程式區塊 風險評級 旁邊小問號圈圈對話框裡的內容
const contentWork6 = (
  <div style={{}}>
    <p>風險評級是以應用程式的業務衝擊，以及在掃描中所找到問題的嚴重性為根據。</p>
  </div>
);


//中間的應用程式區塊 風險評級 旁邊小問號圈圈對話框裡的內容
const contentWork7 = (
  <div style={{}}>
    <p>符合規範與不符規範應用程式的數量。</p>
  </div>
);


//中間的應用程式區塊 風險評級 旁邊小問號圈圈對話框裡的內容
const contentWork8 = (
  <div style={{}}>
    <p>這些數字作為 KPI 中顯示之「作用中的問題」總數的一部分進行計算。</p>

    <a href="https://help.hcl-software.com/appscan/ASoC/ent_dashboard_charts.html" target='blank'>進一步瞭解</a>
  </div>
);

//最下面三塊中最左邊的Scans區塊標題旁邊小問號圈圈對話框裡的內容
const popoverContent = (
  <div>
    <p>'Scans' refers to both scans and executions.</p>
    <p>'Completed' includes both completed and partial scans. 'Failed' refers only to failed scans.</p>
  </div>
);

//最下面三塊中最左邊的Scans區塊 "Scans by technology"旁邊小問號圈圈對話框裡的內容
const popoverContent2 = (
  <div>
    <p>Includes executions, deleted scans, and failed scans (personal scans are not included).</p>
    <p>IAST - counts active connected sessions over time.</p>
  </div>
);

//最下面三塊中 中間的Technology區塊 "Applications by technology"旁邊小問號圈圈對話框裡的內容
const popoverContent3 = (
  <div>
    <p>Applications are divided by their technologies. Applications with no scans have no specified technology.</p>
  </div>
);



//問題區塊的設定
const gridStyle = {
  width: '50%', // 設置寬度，例如讓每個 grid 占據 50% 的寬度
  height: '200px', // 設置高度，例如 200px
  textAlign: 'center', // 讓文字置中
};



function Dashboard() {

  return (
    <Layout>

      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 左側的 "儀表板" */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">儀表板</Text>
        </div>
      </header>

      <div >
        <div style={{
          display: 'flex',
          // justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 160px', // 根據需要調整 padding
        }}>
            {/* 時間區間選擇 */}
            <RangePicker presets={rangePresets} onChange={onRangeChange} /> 
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Text>業務單位:</Text>
            &nbsp;&nbsp;
            <Select
                defaultValue="全部"
                style={{
                  width: 192,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: '全部',
                    label: '全部',
                  },
                  {
                    value: 'Marketing',
                    label: 'Marketing',
                  },
                  {
                    value: 'On-prem',
                    label: 'On-prem',
                  },
                  {
                    value: 'Cloud',
                    label: 'Cloud',
                  },
                  {
                    value: 'GSS',
                    label: 'GSS',
                  },
                  {
                    value: 'Default Business Unit',
                    label: 'Default Business Unit',
                  },
                  ]}
            />
             &nbsp;&nbsp;&nbsp;&nbsp;
            <Text>資產群組:</Text>
            &nbsp;&nbsp;
            <Select
                  defaultValue="全部"
                  style={{
                    width: 192,
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: '全部',
                      label: '全部',
                    },
                    {
                      value: 'JWisdom',
                      label: 'JWisdom',
                    },
                    ]}
            />
        </div>
        
        {/* 中間四小塊區塊 */}
        <div class="four-container"  style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 160px', // 根據需要調整 padding
        }}>
          <Card
            style={{
              width: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // 垂直排列
                alignItems: 'flex-start', // 保持靠左對齊
              }}
            >
              <p style={{ margin: 0 }}>0</p> {/* 數字0 */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>作用中的問題</p> {/* 作用中的問題 */}
                <Popover
                  placement="bottomLeft"
                  content={contentWork}
                  title="作用中的問題"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '5px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>
            </div>
          </Card>

          <Card
            style={{
              width: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // 垂直排列
                alignItems: 'flex-start', // 保持靠左對齊
              }}
            >
              <p style={{ margin: 0 }}> - </p> {/* 數字0 */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>MTTR 問題</p> {/* 作用中的問題 */}
                <Popover
                  placement="bottomLeft"
                  content={contentWork2}
                  title="MTTR 問題"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '5px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>
            </div>
          </Card>

          <Card
            style={{
              width: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // 垂直排列
                alignItems: 'flex-start', // 保持靠左對齊
              }}
            >
              <p style={{ margin: 0 }}>0</p> {/* 數字0 */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>應用程式</p> {/* 作用中的問題 */}
                <Popover
                  placement="bottomLeft"
                  content={contentWork3}
                  title="掃描"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    // icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '5px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>
            </div>
          </Card>

          <Card
            style={{
              width: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column', // 垂直排列
                alignItems: 'flex-start', // 保持靠左對齊
              }}
            >
              <p style={{ margin: 0 }}>0</p> {/* 數字0 */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: 0 }}>掃描</p> {/* 作用中的問題 */}
                <Popover
                  placement="bottomLeft"
                  content={contentWork3}
                  title="掃描"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '5px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>
            </div>
          </Card>
        </div>

        
        
        {/* 中間的問題區塊 */}
        <div class="question-container" style={{margin:'0 160px'}}>
          <Card title="問題"  >
          <Card.Grid hoverable={false} style={{ ...gridStyle, position: 'relative' }}>
              <Card style={{ backgroundColor: 'transparent', border: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ position: 'absolute', top: '8px', left: '8px', display: 'flex', alignItems: 'center' }}>
                  <Text>作用中的問題</Text>
                  <Popover
                    placement="bottomLeft"
                    content={contentWork4}
                    title="作用中的問題"
                    trigger="click"
                    overlayStyle={{ width: '300px', height: '200px' }}
                  >
                    <Button
                      icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                      shape="circle"
                      style={{
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        marginLeft: '3px' // 按鈕和文字之間的距離
                      }}
                    />
                  </Popover>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <img
                    src="/image/no-chart-data.svg"
                    alt="no-chart-data"
                    style={{ width: '64px', height: '64px', color: '#9E9E9E', marginTop: '32px' }}
                  />
                  <p style={{ marginTop: '8px' }}>沒有資料</p>
                </div>
              </Card>
            </Card.Grid>

            <Card.Grid hoverable={false} style={{ ...gridStyle, position: 'relative' }}>
              <Card style={{ backgroundColor: 'transparent', border: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ position: 'absolute', top: '8px', left: '8px', display: 'flex', alignItems: 'center' }}>
                  <Text>解決問題的平均時間</Text>
                  <Popover
                    placement="bottomLeft"
                    content={contentWork5}
                    title="解決問題的平均時間"
                    trigger="click"
                    overlayStyle={{ width: '300px', height: '200px' }}
                  >
                    <Button
                      icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                      shape="circle"
                      style={{
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        marginLeft: '3px' // 按鈕和文字之間的距離
                      }}
                    />
                  </Popover>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <img
                    src="/image/no-chart-data.svg"
                    alt="no-chart-data"
                    style={{ width: '64px', height: '64px', color: '#9E9E9E', marginTop: '32px'}}
                  />
                  <p style={{ marginTop: '8px' }}>沒有資料</p>
                </div>
              </Card>
            </Card.Grid >

            <Card.Grid hoverable={false} style={{ ...gridStyle, width: '100%', height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="horizontal-line" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <div className="line" style={{ width: '20px', height: '2px', backgroundColor: '#AE0000', marginRight: '5px' }}></div>
                <p>重大</p>
              </div>

              <div className="horizontal-line" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <div className="line" style={{ width: '20px', height: '2px', backgroundColor: '#FF359A', marginRight: '5px' }}></div>
                <p>高</p>
              </div>
              
              
              <div className="horizontal-line" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <div className="line" style={{ width: '20px', height: '2px', backgroundColor: '#FF5809', marginRight: '5px' }}></div>
                <p>中</p>
              </div>
              
              <div className="horizontal-line" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                <div className="line" style={{ width: '20px', height: '2px', backgroundColor: '#FFD700', marginRight: '5px' }}></div>
                <p>低</p>
              </div>

              <div className="horizontal-line" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="line" style={{ width: '20px', height: '2px', backgroundColor: '#A9A9A9', marginRight: '5px' }}></div>
                <p>參考資訊</p>
              </div>
            </Card.Grid>



          </Card>

        </div>
        
        <br/>
        
        {/* 中間應用程式區塊 */}
        <div class="app-container" style={{margin:'0 160px'}}>
          <Card title="應用程式"  >

            <Card.Grid hoverable={false} style={{ ...gridStyle, width: '33.33%',height:'400px' }}>
              <Card style={{ backgroundColor: 'transparent', border: 'none', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', marginBottom: '8px' }}>
                  <Text>風險評級</Text>
                  <Popover
                    placement="bottomLeft"
                    content={contentWork6}
                    title="風險評級"
                    trigger="click"
                    overlayStyle={{ width: '300px', height: '200px' }}
                  >
                    <Button
                      icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                      shape="circle"
                      style={{
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        marginLeft: '3px', // 按鈕和文字之間的距離
                      }}
                    />
                  </Popover>
                </div>

                {/* 儀錶板 中間應用程式區塊 風險評級橫條 */}

                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>重大</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '8px' }}>                  
                  <div className="progress-bar"></div>
                  <span style={{ marginLeft: '8px' }}>0</span>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>高</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '8px' }}>                  
                  <div className="progress-bar"></div>
                  <span style={{ marginLeft: '8px' }}>0</span>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>中</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '8px' }}>                  
                  <div className="progress-bar"></div>
                  <span style={{ marginLeft: '8px' }}>0</span>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>低</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '8px' }}>                  
                  <div className="progress-bar"></div>
                  <span style={{ marginLeft: '8px' }}>0</span>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>不明</div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '8px' }}>                  
                  <div className="progress-bar"></div>
                  <span style={{ marginLeft: '8px' }}>0</span>
                </div>

              </Card>
            </Card.Grid>



            <Card.Grid hoverable={false} style={{ ...gridStyle, width: '33.33%', height: '400px', position: 'relative' }}>
              <div style={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                <Text>符合規範與不符規範</Text>
                <Popover
                  placement="bottomLeft"
                  content={contentWork7}
                  title="原則"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '3px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>

              {/* 中間的沒有資料 */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Progress type="dashboard" percent={100} />
              </div>
            </Card.Grid>


            <Card.Grid hoverable={false} style={{ ...gridStyle, width: '33.33%', height: '400px', position: 'relative' }}>
              <div style={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                <Text>有最多作用中問題的應用程式</Text>
                <Popover
                  placement="bottomLeft"
                  content={contentWork8}
                  title="有最多作用中問題的應用程式"
                  trigger="click"
                  overlayStyle={{ width: '300px', height: '200px' }}
                >
                  <Button
                    icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                    shape="circle"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'transparent',
                      boxShadow: 'none',
                      padding: '0',
                      marginLeft: '3px' // 按鈕和文字之間的距離
                    }}
                  />
                </Popover>
              </div>

              {/* 中間的沒有資料 */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <img
                  src="/image/no-chart-data.svg"
                  alt="no-chart-data"
                  style={{ width: '64px', height: '64px', color: '#9E9E9E' }}
                />
                <br />
                <p>沒有資料</p>
              </div>
            </Card.Grid>


          </Card>

        </div>
        <br/>


        {/* 最下面三個區塊 */}
        <div  class="scan-container" style={{margin:'0 160px'}} >
          <Row gutter={16}>
            <Col span={8}>
              <div className="scan-card">
                  <div className="scan-header">
                    <span>Scans</span>
                      <Popover content={popoverContent} title="Scans" placement="right" overlayStyle={{ width: '350px', height: '200px' }}>
                        <Button
                          shape="circle"
                          icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                          style={{ marginLeft: 8, border: 'none', boxShadow: 'none' }}                    
                        />
                      </Popover>
                  </div>
                  
                  <div className="scan-status">
                    <div className="status-completed">
                      <CheckCircleOutlined style={{ color: '#4CAF50' }} />
                      <div>
                        <p>0</p>
                        <span>已完成</span>
                      </div>
                    </div>
                    
                    <div className="status-failed">
                      <CloseCircleOutlined style={{ color: '#F44336' }} />
                      <div>
                        <p>0</p>
                        <span>失敗</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="scan-technology">
                    <div className="technology-header">
                      <span>Scans by technology</span>
                      <Popover content={popoverContent2} title="Scans by technology" placement="right" overlayStyle={{ width: '350px', height: '200px' }}>
                        <Button
                          shape="circle"
                          icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                          style={{ marginLeft: 8, border: 'none', boxShadow: 'none' }}                    
                        />
                      </Popover>
                    </div>
                    
                    <div className="no-data">
                      <img
                        src="/image/no-chart-data.svg"
                        alt="no-chart-data"
                        style={{ width: '64px', height: '64px', color: '#9E9E9E' }}
                      />
                      <br/>
                      <p>沒有資料</p>
                    </div>
                  </div>
              </div>
            </Col>


            <Col span={8}>
            <div className="scan-card">
                  <div className="scan-header">
                    <span>Technology</span>
                  </div>
                  
                  <div className="scan-technology">
                    <div className="technology-header">
                      <span>Applications by technology</span>
                      <Popover content={popoverContent3} title="Applications by technology" placement="right" overlayStyle={{ width: '350px', height: '200px' }}>
                        <Button
                          shape="circle"
                          icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                          style={{ marginLeft: 8, border: 'none', boxShadow: 'none' }}                    
                        />
                      </Popover>
                    </div>

                    <Link to="/" style={{ textDecoration: 'none' }}>
                      <div className="scan-Technology">
                        <Card
                          style={{
                            width: 600,
                            height: 360,
                            border:'none',
                            backgroundColor:'#CFE2FF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <p>Applications without scans</p>
                        </Card>                     
                      </div>
                    </Link>
                  </div>

              </div>
            </Col>


            <Col span={8}>
              <div className="scan-card">
                  <div className="scan-header">
                    <span>SCA</span>
                  </div>
                
                  
                  <div className="scan-technology">
                    <div className="technology-header">
                      <span>Scans by technology</span>
                    </div>                   
                    <div className="no-data">
                      <img
                        src="/image/no-chart-data.svg"
                        alt="no-chart-data"
                        style={{ width: '64px', height: '64px', color: '#9E9E9E' }}
                      />
                      <br/>
                      <p>沒有資料</p>
                    </div>
                  </div>
              </div>
            </Col>

          </Row>
        </div>

      </div>

    </Layout>
  );
}

export default Dashboard;
