import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Dropdown, Space,Select, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined,ExportOutlined } from '@ant-design/icons';

function Login() {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/'); // 指定跳轉的路由
    };

    return (
        <div style={{ 
            backgroundColor: '#F2F2F2', 
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                width: '400px',
                border: '1px solid #DCDCDC',
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '30px',
                transition: 'height 0.3s ease', // 平滑高度變化
            }}>
                
                <Form
                    name="basic"
                    layout="vertical"
                    style={{
                        width: '100%',
                        maxWidth: '340px'
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <h1 style={{ textAlign: 'center' }}><strong>Software</strong></h1>
                    <hr/>

                    <h3 style={{ textAlign: 'center' }}><strong>Create HCLSoftware ID</strong></h3>
                    <Form.Item
                        
                        name="First Name"
                        required
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                        ]}
                    >
                        <Input style={{height:'40px',width:'330px'}} placeholder='First Name*'/>
                    </Form.Item>

                    <Form.Item
                        
                        name="Middle Name"
                        required
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                        ]}
                    >
                        <Input style={{height:'40px',width:'330px'}} placeholder='Middle Name*'/>
                    </Form.Item>

                    <Form.Item
                        
                        name="Last Name"
                        required
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                        ]}
                    >
                        <Input style={{height:'40px',width:'330px'}} placeholder='Last Name*'/>
                    </Form.Item>

                    <Form.Item
                        
                        name="Email"
                        required
                        rules={[
                            {
                                required: true,
                                message: ' ',
                            },
                        ]}
                    >
                        <Input style={{height:'40px',width:'330px'}} placeholder='Email*'/>
                    </Form.Item>

                    <Form.Item>
                        <Select style={{height:'40px',width:'330px'}}  placeholder='Country*'>
                            <Select.Option value="Taiwan">Taiwan</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="register"
                        valuePropName="checked"
                    >
                        <Checkbox>I am not a US Federal Government employee or agency representative, nor am I submitting on behalf of one.<a href="https://www.hcl-software.com/industries/us-federal-government" target='blank'> More info <ExportOutlined /></a></Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="register2"
                        valuePropName="checked2"
                    >
                        <Checkbox>I agree to the <a href="https://www.hcl-software.com/legal/privacy" target="blank">HCLSoftware Privacy statement <ExportOutlined /></a></Checkbox>
                    </Form.Item>

                    <p>* indicates required field</p>

                    <Form.Item style={{ textAlign: 'center' }}>
                         <Button style={{height:'32px',width:'330px',background:'#0044BB',color: '#FFFFFF'}}  htmlType="submit" onClick={handleLogin}>
                            Register
                        </Button>
                    </Form.Item>

                </Form>
 
                <Button style={{border:'none',boxShadow:'none', textDecoration: 'underline' }} onClick={handleLogin}>
                    返回登入
                </Button>
            </div>
        </div>
    );
}

export default Login;
