import './App.css';

// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import Register from './pages/Register';

import Myapp from './pages/Myapp';
import Scan from './pages/Scan';
import OsLibraries from './pages/OsLibraries';
import Dashboard from './pages/Dashboard';

import Plugins from './pages/Plugin';
import Presences from './pages/Presence';
import Apikey from './pages/Apikey';

import Policies from './pages/Policies';
import Domains from './pages/Domains';
import Subscriptions from './pages/Subscriptions';
import Audit from './pages/Audit';

// 在 index.js 或 App.js 中
import 'antd/dist/reset.css';


function App() {

  
  return (
    
    <Router>
      <div>

        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/ForgetPassword" element={<ForgetPassword/>} />
          <Route path="/Register" element={<Register/>} />

          <Route path="/myapp" element={<Myapp/>} />
          <Route path="/scan" element={<Scan />} />
          <Route path="/osLibraries" element={<OsLibraries />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/plugins" element={<Plugins />} />
          <Route path="/presences" element={<Presences />} />
          <Route path="/apikey" element={<Apikey />} />

          <Route path="/policies" element={<Policies />} />
          <Route path="/domains" element={<Domains />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/audit" element={<Audit />} />

        </Routes>

      
      </div>
    </Router>
  );
}

export default App;
