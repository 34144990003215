// pages/Home.js
import '../App.css';
import React, { useState } from 'react';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex,DatePicker, ConfigProvider,Row, Col,Pagination,message, Upload, Steps   } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,DownloadOutlined,FilterOutlined,InboxOutlined} from '@ant-design/icons';
import Layout from '../Layout';
// import 'node_modules/antd/dist/antd.css';


import 'bootstrap-icons/font/bootstrap-icons.css';

import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';

import { CopyOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { Option } = Select;



//AppScan Presence標題旁邊小問號圈圈對話框裡的內容
const contentAppScan = (
    <div style={{
      width: '600px',          // 設置固定寬度
      height: '200px',         // 設置固定高度
      overflowY: 'auto',       // 當內容超過高度時顯示垂直滾動條
      wordWrap: 'break-word',  // 長字換行處理，防止文字溢出
    }}>
    <p>An AppScan Presence on your server enables you to scan sites not accessible from the Internet. You can run dynamic analysis (DAST) directly or connect to enterprise repositories (GitHub, for example) for static analysis (SAST).</p>
    <br/>
    <Text>Learn more:</Text>   
    <br/>
    <a href="https://help.hcl-software.com/appscan/ASoC/Presence1.html" target="blank">Dynamic analysis using an AppScan Presence</a>
    <br/>
    <a href="https://help.hcl-software.com/appscan/ASoC/appseccloud_git_connect.html#appseccloud_git_connect__section_u4b_zl1_ccc" target="blank">Static analysis of a repository using an AppScan Presence</a>
  </div>
);



//過濾鈕設定
const defaultCheckedList = (value) => {
  console.log(`selected ${value}`);
};

//過濾按鈕對話框得內容
const itemsFilter = [
  {
    label: '狀態',
    key: '1',
    children:(
      <div>
        <p>狀態</p>
        <Checkbox>已連接</Checkbox><br/><br/>
        <Checkbox>尚未使用</Checkbox><br/><br/>
        <Checkbox>金鑰已過期</Checkbox><br/><br/>
        <Checkbox>金鑰尚未使用</Checkbox><br/><br/>
        <Checkbox>非作用中</Checkbox><br/><br/>
        <Checkbox>由使用者停用</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '作業系統',
    key: '2',
    children: (
      <div>
        <p>作業系統</p>
        <Checkbox>Linux</Checkbox><br/><br/>
        <Checkbox>Windows</Checkbox><br/><br/>
      </div>
    )
  },
];


//Table表格欄位名稱設定
const columns = [
  {
    title: 'ID',
    dataIndex: 'ID',
    key: '1',
    sorter: (a, b) => {
      const valA = String(a.ID);
      const valB = String(b.ID);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
    render: (text) => (
      <>
        {text}{' '}
        <Text
          copyable={{
            text: text,
            icon: [
              <CopyOutlined key="copy-icon" style={{ color: '#272727',marginLeft: 2 }} />, // 可調整顏色
              
            ],
          }}
        />
      </>
    ),
  },
  {
    title: '主機名稱',
    dataIndex: '主機名稱',
    key: '2',
    sorter: (a, b) => {
      const valA = String(a.主機名稱);
      const valB = String(b.主機名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '作業系統',
    dataIndex: '作業系統',
    key: '3',
    sorter: (a, b) => {
      const valA = String(a.作業系統);
      const valB = String(b.作業系統);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '儲存庫伺服器',
    dataIndex: '儲存庫伺服器',
    key: '4',
    sorter: (a, b) => {
      const valA = String(a.儲存庫伺服器);
      const valB = String(b.儲存庫伺服器);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '名稱',
    dataIndex: '名稱',
    key: '5',
    sorter: (a, b) => {
      const valA = String(a.名稱);
      const valB = String(b.名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '狀態',
    dataIndex: '狀態',
    key: '6',
    sorter: (a, b) => {
      const valA = String(a.狀態);
      const valB = String(b.狀態);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '金鑰到期日',
    dataIndex: '金鑰到期日',
    key: '7',
    sorter: (a, b) => {
      const valA = String(a.金鑰到期日);
      const valB = String(b.金鑰到期日);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },

];



//最右上角 建立Presence按鈕裡面的Steps

const { Step } = Steps;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};



function Presences() {
  
  //過濾鈕和日期鈕設定
  const [openFilter, setOpenFilter] = useState(false);


  const handleOpenChangeFilter = (newOpen) => {
    setOpenFilter(newOpen);
  };


  const hideFilter = () => {
    setOpenFilter(false);
  };


//最右上方 建立應用程式按鈕 彈跳視窗 設定
  const [modalNewApp, setmodalNewApp] = useState(false);


//下拉式選單，及表格固定欄位設定--更新金鑰 

const [isModalOpen, setIsModalOpen] = useState(false);
const [currentRecord, setCurrentRecord] = useState(null);

const showModal = () => {
  setIsModalOpen(true);
};

const handleOk = () => {
  setIsModalOpen(false);
};

const handleCancel = () => {
  setIsModalOpen(false);
};

const handleButtonClick = (record) => {
  setCurrentRecord(record); // 將當前行的資料設置到狀態
  showModal();
  console.log("編輯行資料:", record);
};

// 固定的欄位設定
const fixedColumn = {
  key: "fixedColumnKey",
  title: "",
  fixed: "right",
  width: 28,
  render: (text, record) => (
    <Button  style={{ border: "none", background: "transparent", boxShadow:"none" }} onClick={() => handleButtonClick(record)}>
      <i className="bi bi-key"></i>
    </Button>

  ),
};



//下拉式選單，及表格固定欄位設定--刪除 Presence

const [isModalOpen2, setIsModalOpen2] = useState(false);
const [currentRecord2, setCurrentRecord2] = useState(null);

const showModal2 = () => {
  setIsModalOpen2(true);
};

const handleOk2 = () => {
  setIsModalOpen2(false);
};

const handleCancel2 = () => {
  setIsModalOpen2(false);
};

const handleButtonClick2 = (record2) => {
  setCurrentRecord2(record2); // 將當前行的資料設置到狀態
  showModal2();
  console.log("編輯行資料:", record2);
};

// 固定的欄位設定
const fixedColumn2 = {
  key: "fixedColumnKey2",
  title: "",
  fixed: "right",
  width: 28,
  render: (text2, record2) => (
    <Button   style={{ border: "none", background: "transparent",boxShadow:"none" }}  onClick={() => handleButtonClick2(record2)}>
      <i class="bi bi-trash3"></i>
    </Button>
  ),
};



//下拉式選單，表格欄位篩選設定

  const defaultCheckedList = ["1","2","3","4","5","6","7"]; // 預選欄位，填入想預選的欄位 keys
  
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const optionsColumns = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = [
    ...columns.map((item) => ({
      ...item,
      hidden: !(Array.isArray(checkedList) && checkedList.includes(item.key)),
    })),
    fixedColumn, // 將固定欄位放在最後
    fixedColumn2, // 將固定欄位放在最後

  ];

//過濾鍵裡的選項設定
  const [tabPosition, setTabPosition] = useState('left');
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);};

////

//最右上角 建立presence按鈕裡的進度條 使用狀態來控制當前步驟
 const [current, setCurrent] = useState(0);
 const [inputValue, setInputValue] = useState(''); // 存儲 Input 的值

 const items = [
   { title: '私有網站掃描', content:(
    <div>
      <p>若要掃描私有網站，您必須在其網路中安裝 AppScan Presence。</p>
      <p>名稱</p>
      <Input
        placeholder="範例：Jane的團隊Presence"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)} // 處理輸入變化
      />
    </div>
   ),},
   { title: '下載 Presence', content:(
    <div>
    <p>若要掃描私有網站，您必須在其網路中安裝 AppScan Presence。</p>
    <p>選取伺服器作業系統</p>
    <Select 
      defaultValue="Windows(64-bit)"
      style={{
        width: 160,
      }}
      onChange={handleChange}
      options={[
        {
          value: 'Windows(64-bit)',
          label: 'Windows(64-bit)',
        },
        {
          value: 'Linux(64-bit)',
          label: 'Linux(64-bit)',
        },
      ]}
    />

    </div>
   ),},
   { title: '啟動 Presence', content: '這是第三步的內容' },
 ];

 // 下一步
 const next = () => {
   setCurrent(current + 1);
 };

 // 上一步
 const prev = () => {
   setCurrent(current - 1);
 };


////

//頁面資料數量控制
const [currentPage, setCurrentPage] = useState(1); // 當前頁面
const [pageSize, setPageSize] = useState(10); // 每頁顯示的項目數
const totalData = 100; // 假設的數據總數
const totalPage = Math.ceil(totalData / pageSize); // 總頁數

  

  // 模擬表格中的數據
  const Tabledata = [
    {
      key: '1',
      ID: '1' ,
      主機名稱: 32,
      作業系統: '12346k',
      儲存庫伺服器: '123',
      名稱: 32,
      狀態: '12346k',
      金鑰到期日: '12346k',
    },
    {
      key: '1',
      ID: '2',
      主機名稱: 32,
      作業系統: '12346k',
      儲存庫伺服器: '123',
      名稱: 32,
      狀態: '12346k',
      金鑰到期日: '12346k',
    },
    {
      key: '1',
      ID: '3',
      主機名稱: 32,
      作業系統: '12346k',
      儲存庫伺服器: '123',
      名稱: 32,
      狀態: '12346k',
      金鑰到期日: '12346k',
    },


  ];

// 定義每頁顯示的數據
const paginatedData = Tabledata.slice((currentPage - 1) * pageSize, currentPage * pageSize);

// 分頁切換處理函數
const handlePageChange = (page, pageSize) => {
  setCurrentPage(page);
    setPageSize(pageSize);
};

//表格的下拉式選單設定
const [selectedItems, setSelectedItems] = useState(defaultCheckedList);


    
///////
  return (
    <Layout>
    
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 左側的 "應用程式" 和 Popover */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">AppScan Presence</Text>
          &nbsp;&nbsp;&nbsp; {/* 添加間距，根據需要調整 */}
          <Space wrap> 
            <Popover placement="bottom" content={contentAppScan} title="AppScan Presence" trigger="click">
              <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                shape="circle"
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                }}
              />
            </Popover>
          </Space>
        </div>

        {/* 最右上側的 "建立應用程式" 按鈕 */}
        <Button
          style={{
            height: '40px',
            width: '118.48px',
            margin: '5px 0 0 0',
          }}
          type="primary"
          onClick={() => setmodalNewApp(true)}
        >
          建立Presence
        </Button>

        {/* 彈出窗口 */}
        <Modal
        title="建立 AppScan Presence"
        centered
        open={modalNewApp}
        onOk={() => next()}
        onCancel={() => setmodalNewApp(false)}
        okText="下一步"
        cancelText="取消"
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button key="cancel" onClick={() => setmodalNewApp(false)}>
              取消
            </Button>
            <div>
              {current > 0 && (
                <Button key="prev" style={{ marginRight: 8 }} onClick={prev}>
                  上一步
                </Button>
              )}
              <Button key="next" type="primary" onClick={next}>
                {current === items.length - 1 ? '完成' : '下一步'}
              </Button>
            </div>
          </div>
        }
      >

          <br/>
          <hr/>
          <br/>
          <div>
            {/* 步驟條 */}
            <Steps current={current} labelPlacement="vertical">
              {items.map((item, index) => (
                <Step key={index} title={item.title} />
              ))}
            </Steps>

            {/* 當前步驟對應的內容 */}
            <div style={{ marginTop: 20 }}>{items[current].content}</div>
          </div>

          <br/>
        </Modal>
      </header>
    </div>

    <br/>
    <br/> 

    <div style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical">
      <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
      &nbsp;&nbsp;
      
        <Popover
           overlayStyle={{ width: '512px', height: '420px' }}
          placement="bottom"
          content={
          <div>
            <Tabs 
              tabPosition={tabPosition}
              items={itemsFilter} // 過濾按鈕裡的選項內容
            />
            <div>
              <Button onClick={hideFilter}>清除</Button>
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>
              &nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
            </div>
          </div>
          }
          title="過濾"       
          trigger="click"
          open={openFilter}
          onOpenChange={handleOpenChangeFilter}
        >
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}} ><FilterOutlined />過濾</Button>
        </Popover>

        &nbsp;&nbsp;


      
      &nbsp;&nbsp;
      <Text style={{fontSize:'28px' ,color:'lightgray'}}>|</Text>
      <Button style={{height:'32px', width:'86.17px',  margin:'5px 0 0 0' ,border:'none',backgroundColor:'transparent', boxShadow: 'none' }}>
          全部清除
      </Button>
    </div>



    <div>
      
      <div
        style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical"
      >
        {/* 左側的總計 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text2">總計: 1</Text>
          &nbsp;&nbsp;&nbsp;
        </div>

        {/* 右側的下拉式選單 */}
        <div style={{ position: 'relative', width: '15%' }}>
           {/* 始終顯示的 placeholder */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: '8px 0px 8px 11px',
            color: selectedItems.length === 0 ? '#272727' : '#272727',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        >
          欄
        </div>
          <Select
            defaultValue={defaultCheckedList} // 設置預選的欄位 keys
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="選取直欄"
            options={optionsColumns}
            onChange={(value) => {
              setCheckedList(value); // 更新選中的值
            }}
            tagRender={() => null} // 隱藏標籤
            dropdownRender={(menu) => <div>{menu}</div>} // 自定義下拉選單
            showSearch={false} // 禁用搜尋功能，禁止使用者輸入
          />
        </div>



      </div>


      <div style={{ margin: '20px 50px' }}>
      {/* 表格部分 */}
      <Table
        columns={newColumns}
        dataSource={paginatedData}
        pagination={false} // 禁用自動分頁
        locale={{
          triggerAsc: '點擊以由小到大排序',
          triggerDesc: '點擊以以由大到小排序',
          cancelSort: '取消排序',
        }}
      />
      <div>
        {/* Modal 更新金鑰 */}
        <Modal 
          title="更新金鑰" 
          open={isModalOpen} 
          onOk={handleOk} 
          onCancel={handleCancel}
          okText="更新"
          cancelText="取消"
          width={500} // 設定寬度（單位為 px）          
        >
          <hr/>
          <p>重要事項：如果您現行的金鑰仍為有效，下載新的金鑰將會使現行金鑰失效。</p>  
          <ul>1.將新的金鑰檔儲存到您的機器。</ul>
          <ul>2.在安裝 Presence 的機器上，於 Presence 檔案的根資料夾中找到 AppScanPresence.key，然後以新的金鑰檔加以改寫。</ul>
          <ul>3.終止 Presence (Ctrl + C)。</ul>
          <ul>4.執行 startPresence.sh (Linux) 或 - Presence.exe (Windows) 來啟動 Presence。</ul>
          <p>您要繼續嗎？</p>
          <hr/>        
        </Modal>

        {/* Modal 刪除 Presence */}
        <Modal 
          title="刪除 Presence" 
          open={isModalOpen2} 
          onOk={handleOk2} 
          onCancel={handleCancel2}
          okText="刪除 Presence"
          okButtonProps={{ style: { backgroundColor: "red", borderColor: "red", color: "white" } }}
          cancelText="取消"
          width={500} // 設定寬度（單位為 px） 
        >
           <hr/>
           <p>您即將從 AppScan 刪除此 Presence：</p>
           <p>請注意，此動作不會將 Presence 檔案從伺服器中刪除。</p>
           <br/><br/>
           <p>您要繼續嗎？</p>
           <br/>
           <hr/>

        </Modal>
      </div>

      {/* 自定義分頁 */}
      <div className="pagination">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `總共 ${total} 個項目數`}
          onChange={handlePageChange}
        />
      </div>
    </div>

        
    </div>

    </Layout>
  );
}


export default Presences;
