import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

function Login() {
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/myapp'); // 指定跳轉的路由
    };

    const handleLogin2 = () => {
        navigate('/ForgetPassword'); // 忘記密碼跳轉的路由
    };

    const handleLogin3 = () => {
        navigate('/Register'); // 註冊跳轉的路由
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleVisibleChange = (flag) => {
        setIsDropdownOpen(flag);
    };

    const menuItems = [
        { key: '1', label: <span style={{ background: 'none', border: 'none' }} onClick={handleLogin2}>忘記密碼？</span> },
        { key: '2', label: <span style={{ background: 'none', border: 'none' }} onClick={handleLogin3}>Register for HCLSoftware ID</span> },
        { key: '3', label: <span style={{ background: 'none', border: 'none' }}><a href="https://support.hcl-software.com/csm?id=guest_csm_creation" target="blank" style={{ color: 'black' }}>說明</a></span> }
    ];

    return (
        <div style={{ 
            backgroundColor: '#F2F2F2', 
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                width: '400px',
                height: isDropdownOpen ? '660px' : '540px', // 動態控制高度
                border: '1px solid #DCDCDC',
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '30px',
                transition: 'height 0.3s ease', // 平滑高度變化
            }}>
                
                <Form
                    name="basic"
                    layout="vertical"
                    style={{
                        width: '100%',
                        maxWidth: '340px'
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <h1 style={{ textAlign: 'center' }}><strong>Software</strong></h1>
                    <hr/>

                    <h3 style={{ textAlign: 'center' }}><strong>登入</strong></h3>
                    <Form.Item
                        label="使用者名稱"
                        name="username"
                        required
                        rules={[
                            {
                                required: true,
                                message: '請輸入使用者名稱',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="密碼"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '請輸入密碼',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>記住我</Checkbox>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'center' }}>
                         <Button style={{height:'32px',width:'330px',background:'#0044BB',color: '#FFFFFF'}}  htmlType="submit" onClick={handleLogin}>
                            登入
                        </Button>
                    </Form.Item>
                </Form>

                <Dropdown
                    menu={{ items: menuItems }}
                    onOpenChange={handleVisibleChange}
                    trigger={['click']}
                    overlayStyle={{
                        boxShadow: 'none', // 移除整個下拉選單的陰影
                        border: 'none',     // 移除下拉選單的外框
                    }}
                    overlayInnerStyle={{
                        boxShadow: 'none', // 再次確認內層陰影移除
                        border: 'none',    // 移除內部框線
                    }}
                    
                >
                    <Button style={{border:'none',boxShadow:'none'}}>
                        需要登入協助嗎？ <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
}

export default Login;
