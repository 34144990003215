// pages/Home.js
import '../App.css';
import React, { useState } from 'react';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex, ConfigProvider,Calendar,Row, Col,Pagination,message, Upload,Drawer,Tooltip,Alert    } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,DownloadOutlined,CalendarOutlined,FilterOutlined,InboxOutlined,InfoCircleOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import Layout from '../Layout';
import { Link } from 'react-router-dom';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMonths } from 'date-fns';

import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';



const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};


//應用程式標題旁邊小問號圈圈對話框裡的內容
const contentAPP = (
  <div style={{}}>
    <p>應用程式是與相同專案相關的掃描集合。其可以是 Web 應用程式、行動應用程式、Web 服務，或是應用程式的任何元件。</p>
    <p>應用程式可讓您評量風險、識別趨勢並確保您的專案符合業界和組織原則的規範。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/ent_create_app_inventory_cm.html" target="blank">進一步瞭解</a>
  </div>
);



//過濾按鈕內選項設定
const options = [  //過濾按鈕中資產群組的選項
  { value: 'JWisdom', label: 'JWisdom' },  
];

const options2 = [  //過濾按鈕中業務單位的選項
  { value: 'Marketing', label: 'Marketing' },  
  { value: 'On-prem', label: 'On-prem' },  
  { value: 'Cloud', label: 'Cloud' },  
  { value: 'GSS', label: 'GSS' },  
  { value: 'Default Business Unit', label: 'Default Business Unit' },  
];


//過濾按鈕對話框得內容
const items = [
  {
    label: '風險評級',
    key: '1',
    children:(
      <div>
        <p>風險評級</p>
        <Checkbox>重大</Checkbox><br/><br/>
        <Checkbox>高</Checkbox><br/><br/>
        <Checkbox>中</Checkbox><br/><br/>
        <Checkbox>低</Checkbox><br/><br/>
        <Checkbox>不明</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '資產群組',
    key: '2',
    children: (
      <div>
        <p>資產群組</p>
        <p>選取或開始輸入</p>

        <Select
          mode="multiple"
          allowClear
          style={{
            width: '100%',
          }}
          placeholder="- 選取 -"
          // defaultValue={options}
          onChange={handleChange}
          options={options}
        />
          </div>
    )
  },
  {
    label: '業務衝擊',
    key: '3',
    children:(
      <div>
        <p>業務衝擊</p>
        <Checkbox>重大</Checkbox><br/><br/>
        <Checkbox>高</Checkbox><br/><br/>
        <Checkbox>中</Checkbox><br/><br/>
        <Checkbox>低</Checkbox><br/><br/>
        <Checkbox>未指定</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '原則',
    key: '4',
    children:(
      <div>
        <p>原則</p>
        <Checkbox>符合規範</Checkbox><br/><br/>
        <Checkbox>不符規範</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '業務單位',
    key: '5',
    children: (
      <div>
        <p>業務單位</p>
        <p>選取或開始輸入</p>

        <Select
          mode="multiple"
          allowClear
          style={{
            width: '100%',
          }}
          placeholder="- 選取 -"
          // defaultValue={options2}
          onChange={handleChange}
          options={options2}
        />
          </div>
    )
  },
  {
    label: '測試狀態',
    key: '6',
    children:(
      <div>
        <p>測試狀態</p>
        <Checkbox>未啟動</Checkbox><br/><br/>
        <Checkbox>處理中</Checkbox><br/><br/>
        <Checkbox>已完成</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '最高嚴重性',
    key: '7',
    children:(
      <div>
        <p>最高嚴重性</p>
        <Checkbox>重大</Checkbox><br/><br/>
        <Checkbox>高</Checkbox><br/><br/>
        <Checkbox>中</Checkbox><br/><br/>
        <Checkbox>低</Checkbox><br/><br/>
        <Checkbox>參考資訊</Checkbox><br/><br/>
        <Checkbox>無</Checkbox><br/><br/>
      </div>
    )
  },

];

//////表格中每一列的編輯按鈕彈跳視窗的內容//////


//表格中每一列的編輯按鈕彈跳視窗的內容 - 存取的選單
const optionsSave = [  //資產群組的選項
  { value: 'JWisdom', label: 'JWisdom' },  
];

const optionsSave2 = [  //業務單位的選項
  { value: 'Cloud', label: 'Cloud' },  
  { value: 'Default Business Unit', label: 'Default Business Unit' }, 
  { value: 'GSS', label: 'GSS' },   
  { value: 'Marketing', label: 'Marketing' },  
  { value: 'MCD_TW', label: 'MCD_TW' },  
  { value: 'On-prem', label: 'On-prem' },  
];


//表格中每一列的編輯按鈕彈跳視窗的內容 - 風險的選單
const optionsRisk = [  //業務衝擊的選項
  { value: '重大', label: '重大' },  
  { value: '高', label: '高' },
  { value: '中', label: '中' },
  { value: '低', label: '低' },
  { value: '未指定', label: '未指定' },
];

const optionsRisk2 = [  //機密性需求的選項
  { value: '高', label: '高' },
  { value: '中', label: '中' },
  { value: '低', label: '低' },
  { value: '未定義', label: '未定義' },
];

const optionsRisk3 = [  //完整性需求的選項
  { value: '高', label: '高' },
  { value: '中', label: '中' },
  { value: '低', label: '低' },
  { value: '未定義', label: '未定義' },
];

const optionsRisk4 = [  //可用性需求的選項
  { value: '高', label: '高' },
  { value: '中', label: '中' },
  { value: '低', label: '低' },
  { value: '未定義', label: '未定義' },
];

const optionsRisk5 = [  //可能的附帶損害的選項
  { value: '高', label: '高' },
  { value: '中高', label: '中高' },
  { value: '中低', label: '中低' },
  { value: '低', label: '低' },
  { value: '無', label: '無' },
  { value: '未定義', label: '未定義' },
];

const optionsRisk6 = [  //目標分佈的選項
  { value: '高', label: '高' },
  { value: '中', label: '中' },
  { value: '低', label: '低' },
  { value: '無', label: '無' },
  { value: '未定義', label: '未定義' },
];

//表格中每一列的編輯按鈕彈跳視窗的內容 - 掃描設定的選單
const sharedProps = { //Presence的選項
  mode: 'multiple',
  style: {
    width: '100%',
  },
  optionsScan:[
    { value: 'AndyTest', label: 'AndyTest' },
    { value: 'dirk_test', label: 'dirk_test' },
    { value: 'Github Runner github_145ba4cf-af05-470', label: 'Github Runner github_145ba4cf-af05-470' },
    { value: 'Github Runner github_5dcb7a5d-251a-4e', label: 'Github Runner github_5dcb7a5d-251a-4e' },
    { value: 'Github Runner github_9c20b6c1-d32d-40', label: 'Github Runner github_9c20b6c1-d32d-40' },
    { value: 'hbrhw', label: 'hbrhw' },
    { value: 'July21', label: 'July21' },
    { value: 'Kevin Local Demo', label: 'Kevin Local Demo' },
  ],
  placeholder: '- 選取 -',
  maxTagCount: 'responsive',
};

const optionsScan2 = [  //測試狀態的選項
  { value: '未啟動', label: '未啟動' },
  { value: '處理中', label: '處理中' },
  { value: '已完成', label: '已完成' },
];





//存取裡的 資產群組旁邊的問號小圈圈
const contentAsset = (
  <div style={{width:'220px'}}>
    <p>指派資產群組（例如「財務」或「工程」），以確保只有適當的使用者可以存取應用程式。</p>
    <p>清單會顯示您與之相關聯的資產群組，且您可以新增更多資產群組。</p>
  </div>
);

//存取裡的 業務單位旁邊的問號小圈圈
const contentAsset2 = (
  <div style={{width:'220px'}}>
    <p>選取應用程式所屬組織中的業務單位，以過濾儀表板中的資料。管理員使用者可以在「組織」>「設定」中建立新的業務單位。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/ent_application_attributes.html" target='blank'>進一步瞭解</a>
  </div>
);

//風險裡的 業務衝擊旁邊的問號小圈圈
const contentRisk = (
  <div style={{width:'220px'}}>
    <p>AppScan 會根據您所定義的業務衝擊，以及掃描中所發現問題的嚴重性，來計算風險評級。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/RiskRating.html" target='blank'>進一步瞭解</a>
  </div>
);

//掃描設定裡的 Presence旁邊的問號小圈圈
const contentScan = (
  <div style={{width:'300px'}}>
    <p>在私有網路上掃描時，您必須使用 AppScan Presence 來授予 AppScan 存取權。您可以為此應用程式定義最多 3 種 Presence，或是每次掃描時選取一個。若要建立並設定新的 AppScan Presence，請前往 
      <Link to="/presences">Presence.</Link>
    </p>
  </div>
);

//清理設定裡的 自動清理旁邊的問號小圈圈
const contentClean = (
  <div style={{width:'300px'}}>
    <p>settings.limitations.tooltip.message</p>
  </div>
);






//日期按鈕內選項設定
const options3 = [
  {
    label: '建立日期',
    value: '建立日期',
  },
  {
    label: '前次更新',
    value: '前次更新',
  },
];




//過濾鈕和日期鈕設定
const defaultCheckedList = (value) => {
  console.log(`selected ${value}`);
};


//匯入鈕對話框裡面的設定
const props = {
  name: 'file',
  multiple: true,
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};


//最右上角 建立應用程式鈕 資產群組旁邊小問號的內容
const contentCreateApp = (
  <div style={{}}>
    <p>指派資產群組（例如「財務」或「工程」），以確保只有適當的使用者可以存取應用程式。</p>
    <p>清單會顯示您與之相關聯的資產群組，且您可以新增更多資產群組。</p>
  </div>
);

//最右上角 建立應用程式鈕 資產群組旁邊小問號的內容
const contentCreateApp2 = (
  <div style={{}}>
    <p>AppScan 會根據您所定義的業務衝擊，以及掃描中所發現問題的嚴重性，來計算風險評級。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/RiskRating.html" target='blank'>進一步瞭解</a>
  </div>
);


//表格每一列編輯中 清理裡 的單選選項
const optionsClean = [
  { label: '手動刪除掃描', value: '手動刪除掃描' ,description: '重要事項：自 2024 年 2 月 18 日起開始，在手動刪除掃描或啟用自動清理之前，超過限制的應用程式將無法執行新的掃描。' },
  { label: '啟用自動清理', value: '啟用自動清理' ,description: '服務將在背景中執行，並視需要刪除最舊的掃描。' },
];



function Myapp() {
  
  //過濾鈕和日期鈕設定
  const [openFilter, setOpenFilter] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const handleOpenChangeFilter = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleOpenChangeDate = (newOpen) => {
    setOpenDate(newOpen);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const hideDate = () => {
    setOpenDate(false);
  };

//最右上方 建立應用程式按鈕 彈跳視窗 設定
  const [modalNewApp, setmodalNewApp] = useState(false);

//匯入按鈕彈跳視窗內容設定
const [modalUpload, setmodalUpload] = useState(false);

///

//表格欄位名稱設定
const [columns, setColumns] = useState([
  {
    title: 'ID',
    dataIndex: 'ID',
    key: '1',   
    sorter: (a, b) => {
      const valA = String(a.ID);
      const valB = String(b.ID);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: 'URL',
    dataIndex: 'URL',
    key: '2',
    sorter: (a, b) => {
      const valA = String(a.URL);
      const valB = String(b.URL);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '上次註解',
    dataIndex: '上次註解',
    key: '3',
    sorter: (a, b) => {
      const valA = String(a.上次註解);
      const valB = String(b.上次註解);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '中嚴重性問題',
    dataIndex: '中嚴重性問題',
    key: '4',
    sorter: (a, b) => {
      const valA = String(a.中嚴重性問題);
      const valB = String(b.中嚴重性問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '主機',
    dataIndex: '主機',
    key: '5',
    sorter: (a, b) => {
      const valA = String(a.主機);
      const valB = String(b.主機);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '低嚴重性問題',
    dataIndex: '低嚴重性問題',
    key: '6',
    sorter: (a, b) => {
      const valA = String(a.低嚴重性問題);
      const valB = String(b.低嚴重性問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '作用中問題',
    dataIndex: '作用中問題',
    key: '7',
    sorter: (a, b) => {
      const valA = String(a.作用中問題);
      const valB = String(b.作用中問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '前次更新',
    dataIndex: '前次更新',
    key: '8',
    sorter: (a, b) => {
      const valA = String(a.前次更新);
      const valB = String(b.前次更新);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '可用性需求',
    dataIndex: '可用性需求',
    key: '9',
    sorter: (a, b) => {
      const valA = String(a.可用性需求);
      const valB = String(b.可用性需求);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '可能的附帶損害',
    dataIndex: '可能的附帶損害',
    key: '10',
    sorter: (a, b) => {
      const valA = String(a.可能的附帶損害);
      const valB = String(b.可能的附帶損害);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '問題總計',
    dataIndex: '問題總計',
    key: '11',
    sorter: (a, b) => {
      const valA = String(a.問題總計);
      const valB = String(b.問題總計);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '完整性需求',
    dataIndex: '完整性需求',
    key: '12',
    sorter: (a, b) => {
      const valA = String(a.完整性需求);
      const valB = String(b.完整性需求);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '已鎖定訂閱',
    dataIndex: '已鎖定訂閱',
    key: '13',
    sorter: (a, b) => {
      const valA = String(a.已鎖定訂閱);
      const valB = String(b.已鎖定訂閱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '建立日期',
    dataIndex: '建立日期',
    key: '14',
    sorter: (a, b) => {
      const valA = String(a.建立日期);
      const valB = String(b.建立日期);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '建立者',
    dataIndex: '建立者',
    key: '15',
    sorter: (a, b) => {
      const valA = String(a.建立者);
      const valB = String(b.建立者);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '應用程式名稱',
    dataIndex: '應用程式名稱',
    key: '16',
    sorter: (a, b) => {
      const valA = String(a.應用程式名稱);
      const valB = String(b.應用程式名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '技術',
    dataIndex: '技術',
    key: '17',
    sorter: (a, b) => {
      const valA = String(a.技術);
      const valB = String(b.技術);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '最高嚴重性',
    dataIndex: '最高嚴重性',
    key: '18',
    sorter: (a, b) => {
      const valA = String(a.最高嚴重性);
      const valB = String(b.最高嚴重性);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '業務單位',
    dataIndex: '業務單位',
    key: '19',
    sorter: (a, b) => {
      const valA = String(a.業務單位);
      const valB = String(b.業務單位);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '業務擁有者',
    dataIndex: '業務擁有者',
    key: '20',
    sorter: (a, b) => {
      const valA = String(a.業務擁有者);
      const valB = String(b.業務擁有者);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '業務衝擊',
    dataIndex: '業務衝擊',
    key: '21',
    sorter: (a, b) => {
      const valA = String(a.業務衝擊);
      const valB = String(b.業務衝擊);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '機密性需求',
    dataIndex: '機密性需求',
    key: '22',
    sorter: (a, b) => {
      const valA = String(a.機密性需求);
      const valB = String(b.機密性需求);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '測試人員',
    dataIndex: '測試人員',
    key: '23',
    sorter: (a, b) => {
      const valA = String(a.測試人員);
      const valB = String(b.測試人員);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '測試狀態',
    dataIndex: '測試狀態',
    key: '24',
    sorter: (a, b) => {
      const valA = String(a.測試狀態);
      const valB = String(b.測試狀態);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '目標分佈',
    dataIndex: '目標分佈',
    key: '25',
    sorter: (a, b) => {
      const valA = String(a.目標分佈);
      const valB = String(b.目標分佈);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '相關聯的原則',
    dataIndex: '相關聯的原則',
    key: '26',
    sorter: (a, b) => {
      const valA = String(a.相關聯的原則);
      const valB = String(b.相關聯的原則);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '符合規範',
    dataIndex: '符合規範',
    key: '27',
    sorter: (a, b) => {
      const valA = String(a.符合規範);
      const valB = String(b.符合規範);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '處理中的問題',
    dataIndex: '處理中的問題',
    key: '28',
    sorter: (a, b) => {
      const valA = String(a.處理中的問題);
      const valB = String(b.處理中的問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '說明',
    dataIndex: '說明',
    key: '29',
    sorter: (a, b) => {
      const valA = String(a.說明);
      const valB = String(b.說明);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '資產群組',
    dataIndex: '資產群組',
    key: '30',
    sorter: (a, b) => {
      const valA = String(a.資產群組);
      const valB = String(b.資產群組);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '資產群組ID',
    dataIndex: '資產群組ID',
    key: '31',
    sorter: (a, b) => {
      const valA = String(a.資產群組ID);
      const valB = String(b.資產群組ID);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '重要問題',
    dataIndex: '重要問題',
    key: '32',
    sorter: (a, b) => {
      const valA = String(a.重要問題);
      const valB = String(b.重要問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '開發聯絡人',
    dataIndex: '開發聯絡人',
    key: '33',
    sorter: (a, b) => {
      const valA = String(a.開發聯絡人);
      const valB = String(b.開發聯絡人);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '風險評級',
    dataIndex: '風險評級',
    key: '34',
    sorter: (a, b) => {
      const valA = String(a.風險評級);
      const valB = String(b.風險評級);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '高嚴重性問題',
    dataIndex: '高嚴重性問題',
    key: '35',
    sorter: (a, b) => {
      const valA = String(a.高嚴重性問題);
      const valB = String(b.高嚴重性問題);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
]);
///




//下拉式選單，及表格固定欄位設定

const [isModalOpen, setIsModalOpen] = useState(false);
const [currentRecord, setCurrentRecord] = useState(null);

const showModal = () => {
  setIsModalOpen(true);
};

const handleOk = () => {
  setIsModalOpen(false);
};

const handleCancel = () => {
  setIsModalOpen(false);
};

const handleButtonClick = (record) => {
  setCurrentRecord(record); // 將當前行的資料設置到狀態
  showModal();
  console.log("編輯行資料:", record);
};

// 固定的欄位設定
const fixedColumn = {
  key: "fixedColumnKey",
  title: "",
  fixed: "right",
  width: 28,
  render: (text, record) => (
    <Button style={{ border: "none", background: "transparent", boxShadow:"none" }} onClick={() => handleButtonClick(record)}>
      <i class="bi bi-pencil"></i>
    </Button>
  ),
};


//下拉式選單，表格欄位篩選設定

  const defaultCheckedList = ["1","2","3","4","5","6","7","8","9","10"]; // 預選欄位，填入想預選的欄位 keys
  
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const optionsColumns = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = [
    ...columns.map((item) => ({
      ...item,
      hidden: !(Array.isArray(checkedList) && checkedList.includes(item.key)),
    })),
    fixedColumn, // 將固定欄位放在最後

  ];



//過濾鍵裡的選項設定
  const [tabPosition, setTabPosition] = useState('left');
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);};


//日期鍵裡時間範圍的選項設定
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isRangeOpen, setRangeOpen] = useState(false);

  const handleSelectRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const quickSelect = (months) => {
    const now = new Date();
    setStartDate(addMonths(now, -months));
    setEndDate(now);}


//表格分頁設定
  const [currentPage, setCurrentPage] = useState(1); // 當前頁面
  const [pageSize, setPageSize] = useState(10); // 每頁顯示的項目數
  const totalData = 100; // 假設的數據總數
  const totalPage = Math.ceil(totalData / pageSize); // 總頁數
  

  // 模擬表格中的數據
  const Tabledata = [
    {
      key: '1',
      ID: '1',
      URL: 32,
      上次註解: '00000',
      中嚴重性問題: '00000',
      主機: 32,
      低嚴重性問題: '00000',
      作用中問題: '00000',
      前次更新: '00000',
      可用性需求: 32,
      可能的附帶損害: '100000',
    },
    {
      key: '2',
      ID: '2',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '3',
      ID: '3',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '4',
      ID: '4',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '5',
      ID: '5',
      URL: 32,
      上次註解: 'aaak',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '6',
      ID: '6',
      URL: 32,
      上次註解: '26drthk',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '7',
      ID: '7',
      URL: 32,
      上次註解: '59grek',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '8',
      ID: '8',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '9',
      ID: '9',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '10',
      ID: '10',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },    
    {
      key: '11',
      ID: '11',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '12',
      ID: '12',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '13',
      ID: '13',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '14',
      ID: '14',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
    {
      key: '15',
      ID: '15',
      URL: 32,
      上次註解: '12346k',
      中嚴重性問題: '123',
      主機: 32,
      低嚴重性問題: '12346k',
      作用中問題: '12346k',
      前次更新: '123',
      可用性需求: 32,
      可能的附帶損害: '12346k',
    },
  ];

// 定義每頁顯示的數據
const paginatedData = Tabledata.slice((currentPage - 1) * pageSize, currentPage * pageSize);

// 分頁切換處理函數
const handlePageChange = (page, pageSize) => {
  setCurrentPage(page);
    setPageSize(pageSize);
};

//表格中編輯按鈕 掃描設定中 Presence 的下拉式選單
const [value, setValue] = useState([]);
const selectProps = {
  value,
  onChange: setValue,
};

//表個中編輯按鈕 清理中 的單選選項
const [valueClean, setValueClean] = useState(1);
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValueClean(e.target.value);
  };

//表格的下拉式選單設定
const [selectedItems, setSelectedItems] = useState(defaultCheckedList);


///////
  return (
    <Layout>
    
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 左側的 "應用程式" 和 Popover */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">應用程式</Text>
          &nbsp;&nbsp;&nbsp; {/* 添加間距，根據需要調整 */}
          <Space wrap> 
            <Popover placement="bottomLeft" content={contentAPP} title="應用程式" trigger="click">
              <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                shape="circle"
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                }}
              />
            </Popover>
          </Space>
        </div>

        {/* 最右上側的 "建立應用程式" 按鈕 */}
        <Button
          style={{
            height: '40px',
            width: '118.48px',
            margin: '5px 0 0 0',
          }}
          type="primary"
          onClick={() => setmodalNewApp(true)}
        >
          建立應用程式
        </Button>

        {/* 彈出窗口 */}
        <Modal
          title="建立應用程式"
          centered
          open={modalNewApp}
          onOk={() => setmodalNewApp(false)}
          onCancel={() => setmodalNewApp(false)}
          okText="Create" // 定義確認按鈕的文字
          cancelText="取消" // 定義取消按鈕的文字
        >
          <hr/>
          <p>名稱*</p>
          <Input placeholder="範例：銀行客戶 GUI" required />
          <br/><br/>

          <p>資產群組</p>
          <p>選取一個群組，或（如果您有許可權）輸入新群組的名稱。</p>
          <div style={{display: 'flex'}}>
            <p>資產群組*</p>
            <Popover placement="topLeft" content={contentCreateApp} title="資產群組" trigger="click">
                <Button
                  icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000', padding:'0 0 8px 0' }} />}
                  shape="circle"
                  style={{
                    backgroundColor: 'transparent',
                    border: 'transparent',
                    boxShadow: 'none',
                  }}
                />
              </Popover>
          </div>
          <Select
            // defaultValue="JWisdom"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'JWisdom',
                label: 'JWisdom',
              },
            ]}
          />
          <br/><br/>
          <p>影響</p>
          <div style={{display: 'flex'}}>
            <p>業務衝擊*</p>
            <Popover placement="topLeft" content={contentCreateApp2} title="業務衝擊" trigger="click">
                <Button
                  icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000', padding:'0 0 8px 0' }} />}
                  shape="circle"
                  style={{
                    backgroundColor: 'transparent',
                    border: 'transparent',
                    boxShadow: 'none',
                  }}
                />
              </Popover>
          </div>
          <Select
            // defaultValue="重大"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: '重大',
                label: '重大',
              },
              {
                value: '高',
                label: '高',
              },
              {
                value: '中',
                label: '中',
              },
              {
                value: '低',
                label: '低',
              },
              {
                value: '未指定',
                label: '未指定',
              },
            ]}
          />
        </Modal>
      </header>
    </div>

    <br/>
    <br/> 

    <div style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical">
      <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
      &nbsp;&nbsp;
      <>
        <Popover
           overlayStyle={{ width: '512px', height: '420px' }}
          placement="bottom"
          content={
          <div>
            <Tabs 
              tabPosition={tabPosition}
              items={items} // 直接使用預定義的 items
            />
            <div>
              <Button onClick={hideFilter}>清除</Button>
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>
              &nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
            </div>
          </div>
          }
          title="過濾"       
          trigger="click"
          open={openFilter}
          onOpenChange={handleOpenChangeFilter}
        >
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}} ><FilterOutlined />過濾</Button>
        </Popover>

        &nbsp;&nbsp;

        <Popover
          placement="bottom"
          content={
          <div>

            <Flex vertical gap="middle">
                <Radio.Group
                  block
                  options={options3}
                  defaultValue="建立日期"
                  optionType="button"
                  buttonStyle="solid"
                />
            </Flex>
            <br/>
            <div className="button-group">
              <button onClick={() => quickSelect(0)}>今天</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(1)}>上個月</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(3)}>過去三個月</button>&nbsp;&nbsp;
              <button onClick={() => setRangeOpen(!isRangeOpen)}>自訂</button>
            </div>
            <br/>
            <div className="date-display">
              {startDate && endDate
                ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                : '請選擇日期'}
            </div>
      
            {isRangeOpen && (
              <div style={{ display: 'flex' }}>
                <DatePicker
                  selected={startDate}
                  onChange={handleSelectRange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}  // 顯示兩個月曆
                  inline
                />
              </div>
            )}
            <br/>
            <div className="action-buttons">
              <Button onClick={() => { setStartDate(null); setEndDate(null); }}>清除</Button>&nbsp;&nbsp;
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>&nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
              {/* <button onClick={() => onApply(startDate, endDate)}>套用</button> */}
            </div>

          </div>
          }
          title="選取日期"
          trigger="click"
          open={openDate}
          onOpenChange={handleOpenChangeDate}
        >        
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}}><CalendarOutlined />日期</Button>
        </Popover>
      </>
      &nbsp;&nbsp;
      <Text style={{fontSize:'28px' ,color:'lightgray'}}>|</Text>
      <Button style={{height:'32px', width:'86.17px',  margin:'5px 0 0 0' ,border:'none',backgroundColor:'transparent', boxShadow: 'none' }}>
          全部清除
      </Button>
    </div>



    <div>
      
      <div
        style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical"
      >
        {/* 左側的總計和匯入按鈕 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text2">總計: 1</Text>
          &nbsp;&nbsp;&nbsp;
          <Button
            style={{
              height: '32px',
              width: '86.17px',
              margin: '5px 0 0 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
            onClick={() => setmodalUpload(true)}
          >
            <DownloadOutlined />
            匯入
          </Button>
        </div>

        {/* 右側的下拉式選單 */}
        <div style={{ position: 'relative', width: '15%' }}>
           {/* 始終顯示的 placeholder */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: '8px 0px 8px 11px',
            color: selectedItems.length === 0 ? '#272727' : '#272727',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        >
          欄
        </div>
          <Select
            defaultValue={defaultCheckedList} // 設置預選的欄位 keys
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="選取直欄"
            options={optionsColumns}
            onChange={(value) => {
              setCheckedList(value); // 更新選中的值
            }}
            tagRender={() => null} // 隱藏標籤
            dropdownRender={(menu) => <div>{menu}</div>} // 自定義下拉選單
            showSearch={false} // 禁用搜尋功能，禁止使用者輸入
          />
        </div>


        {/* 彈出窗口 */}
        <Modal
          title="從 CSV 檔匯入應用程式"
          centered
          open={modalUpload}
          onOk={() => setmodalUpload(false)}
          onCancel={() => setmodalUpload(false)}
          okText="匯入" // 定義確認按鈕的文字
          cancelText="取消" // 定義取消按鈕的文字
        >
          <hr/>
          <p>CSV 檔案中的欄位標題和值必須對於 AppScan 應用程式有效。</p>
          <p>上傳檔案</p>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">在這裡拖放檔案或按一下以瀏覽</p>
          </Dragger>
          <br/>
          <p>CSV：AppScan 結構 CSV 格式的應用程式詳細資料</p>
          <p>資產群組*</p>
          <Select
            defaultValue="JWisdom"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'JWisdom',
                label: 'JWisdom',
              },
            ]}
          />
        </Modal>
      </div>


      <div style={{ margin: '20px 50px' }}>
      {/* 表格部分 */}
      <Table
        columns={newColumns}
        dataSource={paginatedData}
        pagination={false} // 禁用自動分頁
       
        locale={{
          triggerAsc: '點擊以由小到大排序',
          triggerDesc: '點擊以由大到小排序',
          cancelSort: '取消排序',
        }}
      ></Table>

      <div>
        {/* Modal 放在這裡，控制顯示與否 */}
        <Modal 
          title="編輯應用程式" 
          open={isModalOpen} 
          onOk={handleOk} 
          onCancel={handleCancel}
          okText="儲存"
          cancelText="取消"
          width={500} // 設定寬度（單位為 px）
          // height={400} // 設定高度並啟用垂直滾動條
          // overflowY= 'auto'
          
        >
        <hr/>

          {/*表格中每一列的編輯按鈕彈跳視窗的內容 */}
          <Tabs
            tabPosition="left" // 固定在左邊
            items={[
              {
                label: '詳細資料',
                key: '1',
                children:(
                  <div>
                    <h3>名稱與說明</h3>
            
                    <h4>名稱*</h4>
                    <Input></Input>
                    <br/>
                    <br/>
            
                    <h4>說明</h4>
                    <TextArea placeholder='範例：銀行應用程式的GUI元件' rows={1} />
                    <br/>
                    <br/>
            
            
                    <h4>技術</h4>
                    <Input placeholder='範例：Web應用程式'></Input>
                    <br/>
                    <br/>
            
            
                    <h4>URL</h4>
                    <Input placeholder='範例：https://demo.test'></Input>
                    <br/>
                    <br/>
            
                    <h4>主機</h4>
                    <Input></Input>
                    <br/>
                    <br/>
            
                    <h3>人員</h3>
            
                    <h4>業務擁有者</h4>
                    <Input></Input>
                    <br/>
                    <br/>
            
                    <h4>開發聯絡人</h4>
                    <Input></Input>
                    <br/>
                    <br/>
            
                    <h4>測試人員</h4>
                    <Input></Input>
                    <br/>
                    <br/>
            
                  </div>
                )
              },
              {
                label: '存取',
                key: '2',
                children: (
                  <div >
                    <h2>組織與存取</h2>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4>資產群組*</h4>
                      <Popover placement="bottomLeft" content={contentAsset} title="資產群組" trigger="click">
                          <Button
                            icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000',padding:'0 0 8px 0' }} />}
                            shape="circle"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'transparent',
                              boxShadow: 'none',
                            }}
                          />
                      </Popover>
                    </div>
                      <Select
                        defaultValue='JWisdom'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsSave}
                      />
                    <br/>
                    <br/>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4>業務單位</h4>
                      <Popover placement="bottomLeft" content={contentAsset2} title="資產群組" trigger="click">
                          <Button
                            icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000',padding:'0 0 8px 0' }} />}
                            shape="circle"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'transparent',
                              boxShadow: 'none',
                            }}
                          />
                      </Popover>
                    </div>
                    <Select
                      // defaultValue=""
                      style={{
                        width: 180,
                      }}
                      allowClear
                      options={optionsSave2}
                      placeholder="- 選取 -"
                    />
                  </div>
                )
              },
              {
                label: '風險',
                key: '3',
                children:(
                  <div>
                    <h2>影響</h2>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4>業務衝擊</h4>
                      <Popover placement="bottomLeft" content={contentRisk} title="業務衝擊" trigger="click">
                          <Button
                            icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000',padding:'0 0 8px 0' }} />}
                            shape="circle"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'transparent',
                              boxShadow: 'none',
                            }}
                          />
                      </Popover>
                    </div>
                    <Select
                        defaultValue='未指定'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk}
                    />
                    <br/>
                    <br/>
                    <h2>安全評級</h2>
            
                    <h4>機密性需求</h4>
                    <Select
                        defaultValue='未定義'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk2}
                    />
                    <br/>
                    <br/>
            
                    <h4>完整性需求</h4>
                    <Select
                        defaultValue='未定義'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk3}
                    />
                    <br/>
                    <br/>
            
                    <h4>可用性需求</h4>
                    <Select
                        defaultValue='未定義'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk4}
                    />
                    <br/>
                     <br/>
            
                    <h4>可能的附帶損害</h4>
                    <Select
                        defaultValue='未定義'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk5}
                    />
                    <br/>
                    <br/>
            
                    <h4>目標分佈</h4>
                    <Select
                        defaultValue='未定義'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsRisk6}
                    />
                    <br/>
                    <br/>
                  </div>
                )
              },
              {
                label: '掃描設定',
                key: '4',
                children:(
                  <div>
                    <h2>掃描設定</h2>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h4>Presence</h4>
                      <Popover placement="bottomLeft" content={contentScan} title="Presence" trigger="click">
                          <Button
                            icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000',padding:'0 0 8px 0' }} />}
                            shape="circle"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'transparent',
                              boxShadow: 'none',
                            }}
                          />
                      </Popover>
                    </div>
                    <Select
                      {...sharedProps}
                      options={sharedProps.optionsScan} // 設定選項
                    />
                    <br/><br/>
                    <Checkbox>不允許以任何其他Presence進行掃描</Checkbox><br/><br/>
                    <h4>測試狀態</h4>
                    <Select
                        defaultValue='未啟動'
                        style={{
                          width: 180,
                        }}
                        onChange={handleChange}
                        options={optionsScan2}
                    />
                    <br/>
                    <br/>
                  </div>
                )
              },
              {
                label: '清理',
                key: '5',
                children: (
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <h2>自動清理</h2>
                      <Popover placement="bottomLeft" content={contentClean} title="自動清理" trigger="click">
                          <Button
                            icon={<QuestionCircleOutlined style={{ fontSize: '14px', color: '#000',padding:'0 0 8px 0' }} />}
                            shape="circle"
                            style={{
                              backgroundColor: 'transparent',
                              border: 'transparent',
                              boxShadow: 'none',
                            }}
                          />
                      </Popover>
                    </div>
                    <p>應用程式可包含的掃描數上限為：2,000</p>
                    <Text style={{fontSize:'14.08px'}}>已達到限制時</Text>
                    <br/><br/>
                    <Radio.Group disabled>
                      {optionsClean.map(optionsClean => (
                        <div key={optionsClean.value} style={{ marginBottom: '8px' }}>
                          <Radio value={optionsClean.value}>{optionsClean.label}</Radio>
                          <div style={{ fontSize: '12px', color: '#888', marginLeft: '24px' }}>
                            {optionsClean.description}
                          </div>
                        </div>
                      ))}
                    </Radio.Group>
                    <Alert
                      message={<span style={{ fontSize: '14.08px' }}>附註</span>}
                      description={<span style={{ fontSize: '14.08px' }}>此設定已停用，因為管理員已為組織定義此設定。</span>}
                      type="info"
                      showIcon
                      icon={<InfoCircleOutlined style={{ fontSize: '14px', padding: '6px 0 0 0', color: '#1890ff' }} />} // 設置圖標顏色
                      style={{
                        width: '300px',   // 調整框的寬度
                        padding: '8px 16px',  // 調整內邊距
                      }}
                    />
                  </div>
                )
              },
            ]}            

          />
          <hr/>
        </Modal>
      </div>

      {/* 自定義分頁 */}
      <div className="pagination">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `總共 ${total} 個項目數`}
          onChange={handlePageChange}

        />
      </div>
    </div>

        
    </div>

    </Layout>
  );
}

export default Myapp;
