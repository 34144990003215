import React from 'react';
import Layout from '../Layout';
import '../App.css';
import { useState } from 'react';
import {  Typography, Button, Popover, Space, Input, Flex, Modal} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;


function Apikey() {
 
  const [modal2Open, setModal2Open] = useState(false);

  return (
    <Layout>
      <header style={{margin:'10px 0 0 10px'}}>
        <Text  className="custom-text">API</Text> 
      </header>

    <div>

      <div style={{ marginTop: '32px', marginLeft: '10px' }}>
        <h1 style={{ fontSize: '24px',color: '#5C5C5C'}} >API金鑰</h1>
      </div>

      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-start', // 控制兩個區塊之間的距離
        alignItems: 'center', // 垂直置中區塊
        gap: '8px' // 可選：設置區塊之間的間距
      }}>

        <div style={{ 
          display: 'flex',
          width: '762.66px',   // 設定區塊的寬度
          height: '388.53px',  // 設定區塊的高度
          backgroundColor: 'white', // 設定區塊的背景色為白色
          // border: '1px solid #ccc', // 可選: 添加邊框來讓區塊更明顯
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' // 可選: 添加陰影效果
          
        }}>

          <div style={{ padding:'28px 44px', width: '762.66px', height: '388.53px',  }} >
            <p>您的「金鑰 ID」會顯示在下方，並且用來產生在您存取 REST API 時可以使用的「金鑰密碼」。</p>
            <p>您必須將這些金鑰都儲存在某個安全的地方，因為在您離開此頁面之後，就無法擷取此「金鑰 ID」的「金鑰密碼」。</p>
            <p>如果您先前已經產生「金鑰密碼」，但是將其遺失，請按一下「產生」按鈕以建立新的「金鑰 ID」和「金鑰密碼」。這樣會讓先前的金鑰組失效。</p>
          
            <label>金鑰 ID</label>
            <Paragraph
              copyable={{
                tooltips: false,
              }}
            >
              12320d123-456789-123045-85696
            </Paragraph>

            <p>您的「金鑰 ID」和「金鑰密碼」是您專屬的。請將此「金鑰密碼」的副本留作記錄。如果遺失金鑰密碼，則必須重新產生。</p>
          
            <Button type="primary" onClick={() => setModal2Open(true)}>
              產生
            </Button>
            <Modal
              title="產生新的「金鑰 ID」和「金鑰密碼」"
              centered
              open={modal2Open}
              onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
            >
              <p>重要事項：請保留這些金鑰的記錄。如果您遺失「金鑰 ID」，您就必須產生新的金鑰組。</p>

            </Modal>
          </div>

        </div>

        <br/>

        <div style={{ 
          width: '381.34px',   // 設定區塊的寬度
          height: '388.53px',  // 設定區塊的高度
          backgroundColor: 'transparent', // 設定區塊的背景色為白色
          // border: '1px solid #ccc', // 可選: 添加邊框來讓區塊更明顯
          // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' // 可選: 添加陰影效果
          
        }}>

          <h5 className="custom-text2">REST API</h5>

          <p  className="custom-text2">可在這裡取得 AppScan REST API：</p>

          <p  className="custom-text2">若要嘗試 API，只需按一下上方的連結即可。因為您已登入，所以系統將會為您產生存取記號，讓您只要登入，即可使用 API。</p>

          <p  className="custom-text2">若要在您的 Script 或自動化中將 API 要求傳送至 AppScan，請執行下列動作：</p>

          <ol>
          <li _ngcontent-ng-c251052484="" class="ng-star-inserted"><p _ngcontent-ng-c251052484="">按一下「產生」按鈕來產生您目前「金鑰 ID」的 API 金鑰密碼。</p></li>
          
          <li _ngcontent-ng-c251052484="" class="ng-star-inserted"><p _ngcontent-ng-c251052484="">使用您的「金鑰 ID」和「金鑰密碼」，在此 REST API 中產生新的載送記號：<strong>api/v4/Account/ApiKeyLogin</strong></p></li>

          <li _ngcontent-ng-c251052484="" class="ng-star-inserted"><p _ngcontent-ng-c251052484="">從現在開始，請在您的所有 API 要求中包括此 Bearer 記號。</p></li>

          </ol>

        </div>

      </div>

    </div>




    </Layout>
  );
}

export default Apikey;
