// pages/About.js
import React from 'react';
import Layout from '../Layout';
import '../App.css';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex, ConfigProvider,Calendar,Row, Col,Pagination,message, Upload,DatePicker,Card   } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,DownloadOutlined,CalendarOutlined,FilterOutlined,InboxOutlined} from '@ant-design/icons';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const { Paragraph,Text } = Typography;

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
];


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


//最左上角 訂閱標題 旁邊小問號圈圈對話框裡的內容
const contentWork = (
  <div style={{}}>
    <p>AppScan 授權是透過您的 FlexNet 帳戶管理。若要更新訂閱或將訂閱新增至現有組織，請聯絡<a href='https://www.hcl-software.com/contact-us' target='_blank'>銷售人員</a>，銷售人員將會引導您完成購買程序，並提供授權金鑰。然後，按一下此頁面頂端的「新增訂閱」。若您的帳戶發生任何其他問題，請聯絡<a href='https://support.hcl-software.com/csm' target='_blank' >支援中心</a>。請在問題單中包含您的「組織 ID」。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/appseccloud_subscription_management.html" target='_blank'>進一步瞭解</a>
  </div>
);



function Subscriptions() {
  return (
    <Layout>
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 左側的 "應用程式" 和 Popover */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">訂閱</Text>
          &nbsp;&nbsp;&nbsp; {/* 添加間距，根據需要調整 */}
          <Space wrap> 
            <Popover placement="bottomLeft" content={contentWork} title="訂閱管理" trigger="click" overlayStyle={{ width: '450px', height: '150px' }}>
              <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                shape="circle"
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                }}
              />
            </Popover>
          </Space>
        </div>
      </header>
      <div style={{ display: 'flex', alignItems: 'center',padding: '0px 0 10px 0', }} >
          <Paragraph style={{display: 'flex',alignItems: 'center',padding: '0 50px',fontSize:'14px'}} >組織名稱：AppScan Trial Organization</Paragraph>
          <Paragraph copyable style={{display: 'flex',alignItems: 'center',padding: '0 50px',fontSize:'14px'}} >組織 ID：123456789-c412</Paragraph>
      </div>
    </div>
    
    <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 300px', // 根據需要調整 padding
          // margin: '30px 200px 30px 200px',
    }}>
      <Text style={{fontSize:'20px',alignItems: 'center',}}>作用中訂閱</Text>
      <br/><br/>
      <Card 
        title={
          <div>
            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Trial</div>
            <div style={{ fontSize: '12px', color: 'gray' }}>附贈 | 包含開放原始碼、IAST</div>
          </div>
        }
        subtitle='Trial'
        headStyle={{ borderBottom: 'none' }}
        bordered={false}
        style={{}}
      >

        <div style={{display: 'flex', alignItems: 'center',}}>
          <PieChart width={300} height={300} margin='0px 0 30px 0' >
            <Pie 
              data={data}
              cx={200} /* x 軸的位置 */
              cy={200} /* y 軸的位置 */
              innerRadius={60} /* 內半徑，設置為 0 表示不顯示圓環 */
              outerRadius={84} /* 外半徑 */
              fill="#8884d8"
              paddingAngle={5} /* 每個區塊之間的間隙 */
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>

          <PieChart width={300} height={300} >
            <Pie 
              data={data}
              cx={200} /* x 軸的位置 */
              cy={200} /* y 軸的位置 */
              innerRadius={60} /* 內半徑，設置為 0 表示不顯示圓環 */
              outerRadius={84} /* 外半徑 */
              fill="#8884d8"
              paddingAngle={5} /* 每個區塊之間的間隙 */
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Card type="inner" title="詳細資料"   bordered={false}>
            <Text>開始時間：2022年6月8日 16:43</Text>
            <br/>
            <Text>結束時間：2030年12月31日 08:00</Text>
            <br/>
            <Paragraph copyable>ID：12345678900 </Paragraph>
          </Card>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Text>同時掃描數上限：10</Text>
        </div>   
     </Card>
    </div>

    <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 300px', // 根據需要調整 padding
          // margin: '30px 200px 30px 200px',
    }}
    >
      <Card
        title="AppScan For You"
        bordered={false}
        style={{
          // width: 300,
        }}
      >
        <p>如果您對於訂閱或貴組織目前使用 Experts on Demand 的花費時數有任何問題，請聯絡您的安全成功合作夥伴。</p>
      </Card>

    </div>


    </Layout>
  );
}

export default Subscriptions;