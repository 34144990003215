// pages/Home.js
import '../App.css';
import React, { useState } from 'react';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex, ConfigProvider,Calendar,Row, Col,Pagination,message, Upload,Alert,Steps,Drawer  } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,CalendarOutlined,FilterOutlined,InboxOutlined,UploadOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import Layout from '../Layout';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMonths } from 'date-fns';


const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};


//網域標題旁邊小問號圈圈對話框裡的內容
const contentAPP = (
  <div style={{height:'108px',width:'500px'}}>
    <p>網域檢視清單列出了您有執行動態 (DAST) 掃描許可權的網域，並允許您驗證其他網域以進行掃描。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/page_domains.html" target='blank'>進一步瞭解</a>
  </div>
);



//過濾按鈕內選項設定
const options = [  //過濾按鈕中資產群組的選項
  { value: 'JWisdom', label: 'JWisdom' },  
];

const options2 = [  //過濾按鈕中業務單位的選項
  { value: 'Marketing', label: 'Marketing' },  
  { value: 'On-prem', label: 'On-prem' },  
  { value: 'Cloud', label: 'Cloud' },  
  { value: 'GSS', label: 'GSS' },  
  { value: 'Default Business Unit', label: 'Default Business Unit' },  
];


//過濾按鈕對話框得內容
const itemsFilter = [
  {
    label: '驗證狀態',
    key: '1',
    children:(
      <div>
        <p>驗證狀態</p>
        <Checkbox>已驗證</Checkbox><br/><br/>
        <Checkbox>擱置</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '驗證方式',
    key: '2',
    children:(
      <div>
        <p>驗證方式</p>
        <Checkbox>電子郵件</Checkbox><br/><br/>
        <Checkbox>檔案</Checkbox><br/><br/>
        <Checkbox>手動</Checkbox><br/><br/>
        <Checkbox>支援</Checkbox><br/><br/>
      </div>
    )
  },

];

//日期按鈕內選項設定
const options3 = [
  {
    label: '已新增日期',
    value: '已新增日期',
  },
  {
    label: '上次掃描',
    value: '上次掃描',
  },
];


//表格欄位名稱設定
const columns = [
  {
    title: '上次掃描',
    dataIndex: '上次掃描',
    key: '1',
    sorter: (a, b) => {
      const valA = String(a.上次掃描);
      const valB = String(b.上次掃描);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '已新增掃描',
    dataIndex: '已新增掃描',
    key: '2',
    sorter: (a, b) => {
      const valA = String(a.已新增掃描);
      const valB = String(b.已新增掃描);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '掃描',
    dataIndex: '掃描',
    key: '3',
    sorter: (a, b) => {
      const valA = String(a.掃描);
      const valB = String(b.掃描);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '新增者',
    dataIndex: '新增者',
    key: '4',
    sorter: (a, b) => {
      const valA = String(a.新增者);
      const valB = String(b.新增者);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '說明',
    dataIndex: '說明',
    key: '5',
    sorter: (a, b) => {
      const valA = String(a.說明);
      const valB = String(b.說明);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '驗證方式',
    dataIndex: '驗證方式',
    key: '6',
    sorter: (a, b) => {
      const valA = String(a.驗證方式);
      const valB = String(b.驗證方式);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '驗證狀態',
    dataIndex: '驗證狀態',
    key: '7',
    sorter: (a, b) => {
      const valA = String(a.驗證狀態);
      const valB = String(b.驗證狀態);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  
];


//過濾鈕和日期鈕設定
const defaultCheckedList = (value) => {
  console.log(`selected ${value}`);
};


//最右上角 驗證新網域按鈕裡面的Steps
const { Step } = Steps;

//最右上角 驗證新網域鈕 第一步網域旁邊小問號的內容
const contentNet = (
  <div style={{height:'108px',width:'500px'}}>
    <p>輸入絕對網域名稱或 FQDN（完整網域名稱）以驗證包含子網域的整個網域。請勿包含通訊協定。例如，輸入 my-domain.com。</p>
    <br/>
    <a href="https://help.hcl-software.com/appscan/ASoC/Domain_verify.html" target='blank'>進一步瞭解</a>
  </div>
);



//表格每一列點選會跑出來的Drawer的設定
const onChange = (key) => {
  console.log(key);
};
const items2 = [
  {
    key: '1',
    label: '詳細資料',
    children: 'Content of Tab Pane 1',
  },
  {
    key: '2',
    label: '設定',
    children: 'Content of Tab Pane 2',
  },
];



function Domains() {
  
  //過濾鈕和日期鈕設定
  const [openFilter, setOpenFilter] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const handleOpenChangeFilter = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleOpenChangeDate = (newOpen) => {
    setOpenDate(newOpen);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const hideDate = () => {
    setOpenDate(false);
  };

//最右上方 驗證新網域按鈕 彈跳視窗 設定
  const [modalNewApp, setmodalNewApp] = useState(false);

//匯出按鈕彈跳視窗內容設定
const [modalOutlined, setmodalOutlined] = useState(false);


//下拉式選單，表格欄位篩選設定

  const defaultCheckedList = columns.map((item) => item.key);  // 預選欄位，填入想預選的欄位 keys

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const optionsColumns = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !(Array.isArray(checkedList) && checkedList.includes(item.key)),
  }));


//過濾鍵裡的選項設定
  const [tabPosition, setTabPosition] = useState('left');
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);};



//最右上角 驗證新網域按鈕裡的進度條 使用狀態來控制當前步驟
const [current, setCurrent] = useState(0);
const [inputValue, setInputValue] = useState(''); // 存儲 Input 的值

const items = [
  { title: '新增網域', content:(
    <div>

      <p>新增網域</p>
      <div style={{display: 'flex',alignItems: 'center'}}> 
      <p>網域</p>
      <Popover placement="bottomLeft" content={contentNet} title="網域" trigger="click">
        <Button
          icon={<QuestionCircleOutlined style={{ fontSize: '12px', color: '#000' }} />}
          shape="circle"
          style={{
          backgroundColor: 'transparent',
          border: 'transparent',
          boxShadow: 'none',
          padding:'0 0 12px 0',
          }}
        />
      </Popover>
    </div>  

     <Input
       placeholder="範例：my-domain.com 或 subdomain.my-domain.com"
       value={inputValue}
       onChange={(e) => setInputValue(e.target.value)} // 處理輸入變化
     />

     <br/>
     <br/>
     
     <Alert style={{height:'120px',width:'450px'}}
        message={<span style={{ fontSize: '14px' }}>附註</span>}
        description={<span style={{ fontSize: '12px' }}>AppScan 會自動驗證指定網域的所有子網域。子網域不需要進行單獨驗證。</span>}
        type="info"
        showIcon
     />

   </div>
  ),},
  { title: '選取方式', content:(
   <div>
   <p>若要掃描私有網站，您必須在其網路中安裝 AppScan Presence。</p>
   <p>選取伺服器作業系統</p>
   <Select 
     defaultValue="Windows(64-bit)"
     style={{
       width: 160,
     }}
     onChange={handleChange}
     options={[
       {
         value: 'Windows(64-bit)',
         label: 'Windows(64-bit)',
       },
       {
         value: 'Linux(64-bit)',
         label: 'Linux(64-bit)',
       },
     ]}
   />

   </div>
  ),},
  { title: '驗證', content: '這是第三步的內容' },
];

// 下一步
const next = () => {
  setCurrent(current + 1);
};

// 上一步
const prev = () => {
  setCurrent(current - 1);
};



//日期鍵裡時間範圍的選項設定
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isRangeOpen, setRangeOpen] = useState(false);

  const handleSelectRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const quickSelect = (months) => {
    const now = new Date();
    setStartDate(addMonths(now, -months));
    setEndDate(now);}


//表格分頁設定
const [currentPage, setCurrentPage] = useState(1); // 當前頁面
const [pageSize, setPageSize] = useState(10); // 每頁顯示的項目數
const totalData = 100; // 假設的數據總數
const totalPage = Math.ceil(totalData / pageSize); // 總頁數

const [drawerVisible, setDrawerVisible] = useState(false);
const [selectedRecord, setSelectedRecord] = useState(null); // 用於儲存選中的行數據

  // 模擬表格中的數據
  const Tabledata = [
    {
      key: '1',
      上次掃描: '123',
      已新增日期: 32,
      掃描: '12346k',
      新增者: '123',
      說明: 32,
      驗證方式: '12346k',
      驗證狀態: '12346k',
    },
    {
      key: '1',
      上次掃描: '123',
      已新增日期: 32,
      掃描: '12346k',
      新增者: '123',
      說明: 32,
      驗證方式: '12346k',
      驗證狀態: '12346k',
    },
    {
      key: '1',
      上次掃描: '123',
      已新增日期: 32,
      掃描: '12346k',
      新增者: '123',
      說明: 32,
      驗證方式: '12346k',
      驗證狀態: '12346k',
    },

  ];

// 定義每頁顯示的數據
const paginatedData = Tabledata.slice((currentPage - 1) * pageSize, currentPage * pageSize);

// 分頁切換處理函數
const handlePageChange = (page, pageSize) => {
  setCurrentPage(page);
  setPageSize(pageSize);
};

  // 打開 Drawer 的函數
  const showDrawer = (record) => {
    setSelectedRecord(record);
    setDrawerVisible(true);
  };

  // 關閉 Drawer 的函數
  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedRecord(null);
  };


//表格的下拉式選單設定
const [selectedItems, setSelectedItems] = useState(defaultCheckedList);


    
///////
  return (
    <Layout>
    
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 左側的 "網域" 和 Popover */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">網域</Text>
          &nbsp;&nbsp;&nbsp; {/* 添加間距，根據需要調整 */}
          <Space wrap> 
            <Popover placement="bottomLeft" content={contentAPP} title="應用程式" trigger="click">
              <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                shape="circle"
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                }}
              />
            </Popover>
          </Space>
        </div>

        {/* 最右上側的 "驗證新網域" 按鈕 */}
        <Button
          style={{
            height: '40px',
            width: '118.48px',
            margin: '5px 0 0 0',
          }}
          type="primary"
          onClick={() => setmodalNewApp(true)}
        >
          驗證新網域
        </Button>

        {/* 彈出窗口 */}
        <Modal
          title="驗證網域"
          centered
          open={modalNewApp}
          onOk={() => next()}
          onCancel={() => setmodalNewApp(false)}
          okText="下一步" // 定義確認按鈕的文字
          cancelText="取消" // 定義取消按鈕的文字
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button key="cancel" onClick={() => setmodalNewApp(false)}>
                取消
              </Button>
              <div>
                {current > 0 && (
                  <Button key="prev" style={{ marginRight: 8 }} onClick={prev}>
                    上一步
                  </Button>
                )}
                <Button key="next" type="primary" onClick={next}>
                  {current === items.length - 1 ? '完成' : '下一步'}
                </Button>
              </div>
            </div>
          }
        >
          <hr/>
          <br/>
          <div>
            {/* 步驟條 */}
            <Steps current={current} labelPlacement="vertical">
              {items.map((item, index) => (
                <Step key={index} title={item.title} />
              ))}
            </Steps>
            
          </div>
          <br/>
        </Modal>
      </header>
    </div>
    <br/>
    <br/> 
    <div style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical">
      <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
      &nbsp;&nbsp;
      <>
        <Popover  //過濾內容設定
           overlayStyle={{ width: '512px', height: '420px' }}
          placement="bottom"
          content={
          <div>
            <Tabs 
              tabPosition={tabPosition}
              items={itemsFilter} // 直接使用預定義的 items
            />
            <div>
              <Button onClick={hideFilter}>清除</Button>
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>
              &nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
            </div>
          </div>
          }
          title="過濾"       
          trigger="click"
          open={openFilter}
          onOpenChange={handleOpenChangeFilter}
        >
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}} ><FilterOutlined />過濾</Button>
        </Popover>

        &nbsp;&nbsp;

        <Popover //日期內容設定
          placement="bottom"
          content={
          <div>

            <Flex vertical gap="middle">
                <Radio.Group
                  block
                  options={options3}
                  defaultValue="已新增日期"
                  optionType="button"
                  buttonStyle="solid"
                />
            </Flex>
            <br/>
            <div className="button-group">
              <button onClick={() => quickSelect(0)}>今天</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(1)}>上個月</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(3)}>過去三個月</button>&nbsp;&nbsp;
              <button onClick={() => setRangeOpen(!isRangeOpen)}>自訂</button>
            </div>
            <br/>
            <div className="date-display">
              {startDate && endDate
                ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                : '請選擇日期'}
            </div>
      
            {isRangeOpen && (
              <div style={{ display: 'flex' }}>
                <DatePicker
                  selected={startDate}
                  onChange={handleSelectRange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}  // 顯示兩個月曆
                  inline
                />
              </div>
            )}
            <br/>
            <div className="action-buttons">
              <Button onClick={() => { setStartDate(null); setEndDate(null); }}>清除</Button>&nbsp;&nbsp;
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>&nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
              {/* <button onClick={() => onApply(startDate, endDate)}>套用</button> */}
            </div>

          </div>
          }
          title="選取日期"
          trigger="click"
          open={openDate}
          onOpenChange={handleOpenChangeDate}
        >        
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}}><CalendarOutlined />日期</Button>
        </Popover>
      </>
      &nbsp;&nbsp;
      <Text style={{fontSize:'28px' ,color:'lightgray'}}>|</Text>
      <Button style={{height:'32px', width:'86.17px',  margin:'5px 0 0 0' ,border:'none',backgroundColor:'transparent', boxShadow: 'none' }}>
          全部清除
      </Button>
    </div>

    <div>
      
      <div
        style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical"
      >
        {/* 左側的總計和匯入按鈕 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text2">總計: 2</Text>
          &nbsp;&nbsp;&nbsp;
          <Button
            style={{
              height: '32px',
              width: '86.17px',
              margin: '5px 0 0 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
            onClick={() => setmodalOutlined(true)}
          >
            <UploadOutlined />
            匯出
          </Button>
        </div>

        {/* 右側的下拉式選單(表格的) */}
        <div style={{ position: 'relative', width: '15%' }}>
           {/* 始終顯示的 placeholder */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: '8px 0px 8px 11px',
            color: selectedItems.length === 0 ? '#272727' : '#272727',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        >
          欄
        </div>
          <Select
            defaultValue={defaultCheckedList} // 設置預選的欄位 keys
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="選取直欄"
            options={optionsColumns}
            onChange={(value) => {
              setCheckedList(value); // 更新選中的值
            }}
            tagRender={() => null} // 隱藏標籤
            dropdownRender={(menu) => <div>{menu}</div>} // 自定義下拉選單
            showSearch={false} // 禁用搜尋功能，禁止使用者輸入
          />
        </div>
        {/* 匯出按鈕的彈出窗口內容 */}
        <Modal
          title="匯出資料"
          centered
          open={modalOutlined}
          onOk={() => setmodalOutlined(false)}
          onCancel={() => setmodalOutlined(false)}
          okText="匯出" // 定義確認按鈕的文字
          cancelText="取消" // 定義取消按鈕的文字
        >
          <hr/>
          <p>另存新檔</p>
          <Input placeholder='預設的檔名'></Input>
          <br/>
          <br/>
          <p>格式</p>
          <Radio.Group name="radiogroup" defaultValue={1}>
            <Radio value={1}>CSV 檔案</Radio>
            <br/>
            <br/>
            <Radio value={2}>JSON 檔案</Radio>
          </Radio.Group>
          <br/>
          <br/>
          <p>項目數: 2</p>
          <Alert message="所有目前顯示的項目都會匯出。" type="info" showIcon />
          <br/>
          <hr/>
        </Modal>
      </div>



      <div style={{ margin: '20px 50px' }}>
      {/* 表格部分 */}
      <Table
        columns={newColumns}
        dataSource={paginatedData}
        pagination={false} // 禁用自動分頁
        onRow={(record) => ({
          onClick: () => showDrawer(record), // 點擊行時打開 Drawer
        })}
        rowClassName="clickable-row" // 增加行的點擊效果
        locale={{
          triggerAsc: '點擊以由小到大排序',
          triggerDesc: '點擊以由大到小排序',
          cancelSort: '取消排序',
        }}
      />

      {/* 自定義分頁 */}
      <div className="pagination">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `總共 ${total} 個項目數`}
          onChange={handlePageChange}
        />
      </div>
      {/* Drawer 組件 */}
      <Drawer
          title={selectedRecord ? selectedRecord.name : ''}
          placement="right"
          onClose={closeDrawer}
          visible={drawerVisible}
          width={400}
      >
          {selectedRecord && (
            <div>
              <p style={{fontSize:'24px'}}>looktv.mn </p>
              <Tabs defaultActiveKey="1" items={items2} onChange={onChange} />
            </div>
          )}
      </Drawer>
    </div>

        
    </div>

    </Layout>
  );
}

export default Domains;

