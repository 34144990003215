import React from 'react';
import Layout from '../Layout';
import '../App.css';
import {  Typography, Button, Popover, Space, Input, Flex } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const content = (
  <div style={{width:'300px'}}>
    <p>搜尋應用程式是否有開放原始碼檔案庫。檢視檔案庫詳細資料，包括使用狀況、版本和授權。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/page_library_details.html" target='blank'>進一步瞭解</a>
  </div>
);

function OsLibraries() {
  return (
    <Layout>

      <div style={{margin:'10px 0 0 10px'}} >
        <header>
          <Text  className="custom-text">開放原始碼檔案庫</Text> 
          <Space wrap>
            <Popover content={content} title="開放原始碼檔案庫" trigger="click">
              <Button 
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />} 
                shape="circle" 
                style={{
                  backgroundColor: 'transparent', // 調整按鈕的背景色為灰白色
                  border: 'transparent', // 邊框顏色
                  boxShadow: 'none' // 去除陰影
                }}
              />
            </Popover>
          </Space>
        </header>
        <div>
        <Text className="custom-text2">在應用程式中搜尋開放原始碼檔案庫</Text> 
        </div>
      </div>

      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // 設置高度為整個視窗高度
        flexDirection: 'column', // 垂直排列元素
        textAlign: 'center' // 中心對齊文字
      }}>
      <img
        alt="Visual Studio 2022"
        src="/image/osLibraries.svg"
        style={{ width: '192px', height: '192px' }}  // 固定寬100px, 高100px
      />
        <p style={{ fontSize: '16px'}}>搜尋檔案庫</p> 
        <p className="custom-text2">AppScan 會搜尋使用者有存取許可權的應用程式。</p> 
        <p className="custom-text2">如果有相符項目，AppScan 會指出哪些應用程式使用檔案庫。</p> 

        <Space size="small">
        <Input placeholder="輸入檔案庫名稱" style={{ width: '304px', height: '32px' }}  />
        <Button type="primary">搜尋</Button>
        </Space>
      </div>


    </Layout>
  );
}

export default OsLibraries;
