// pages/Home.js
import '../App.css';
import React, { useState } from 'react';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex, ConfigProvider,Calendar,Row, Col,Pagination,message, Upload,Alert,Steps, Drawer  } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,CalendarOutlined,FilterOutlined,InboxOutlined,UploadOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import Layout from '../Layout';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMonths } from 'date-fns';


const { Text } = Typography;
const { Option } = Select;

const handleChange = (value) => {
  console.log(`selected ${value}`);
};


//過濾按鈕內選項設定
const options = [  //過濾按鈕中活動的選項
  { value: '更新', label: '更新' }, 
  { value: '更新大量問題', label: '更新大量問題' }, 
  { value: '取消關聯', label: '取消關聯' }, 
  { value: '封鎖網域', label: '封鎖網域' }, 
  { value: '建立', label: '建立' }, 
  { value: '建立報告', label: '建立報告' }, 
  { value: '建立關聯', label: '建立關聯' }, 
  { value: '重設', label: '重設' }, 
  { value: '容許網域', label: '容許網域' }, 
  { value: '移除', label: '移除' }, 
  { value: '新增', label: '新增' }, 
  { value: '邀請使用者', label: '邀請使用者' }, 
];

const options2 = [  //過濾按鈕中業務單位的選項
  { value: '大量問題', label: '大量問題' }, 
  { value: '角色', label: '角色' }, 
  { value: '使用者', label: '使用者' }, 
  { value: '修正程式群組', label: '修正程式群組' }, 
  { value: '原則', label: '原則' }, 
  { value: '掃描', label: '掃描' }, 
  { value: '掃描執行', label: '掃描執行' }, 
  { value: '組織', label: '組織' }, 
  { value: '資產群組', label: '資產群組' }, 
  { value: '網域', label: '網域' }, 
  { value: '應用程式', label: '應用程式' }, 
  { value: 'IAST階段作業', label: 'IAST階段作業' }, 
  { value: 'Presence', label: 'Presence' }, 
];


//過濾按鈕對話框得內容
const itemsFilter = [
  {
    label: '種類',
    key: '1',
    children:(
      <div>
        <p>種類</p>
        <Checkbox>登入</Checkbox><br/><br/>
        <Checkbox>建立</Checkbox><br/><br/>
        <Checkbox>更新</Checkbox><br/><br/>
        <Checkbox>刪除</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '活動',
    key: '2',
    children: (
      <div>
        <p>活動</p>
        <p>選取或開始輸入</p>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: '100%',
          }}
          placeholder="- 選取 -"
          // defaultValue={options}
          onChange={handleChange}
          options={options}
        />
          </div>
    )
  },
  {
    label: '物件',
    key: '3',
    children: (
      <div>
        <p>物件</p>
        <p>選取或開始輸入</p>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: '100%',
          }}
          placeholder="- 選取 -"
          // defaultValue={options}
          onChange={handleChange}
          options={options2}
        />
          </div>
    )
  },

];

//日期按鈕內選項設定
const options3 = [
  {
    label: '日期',
    value: '日期',
  },
];


//表格欄位名稱設定
const columns = [
  {
    title: '使用者ID',
    dataIndex: '使用者ID',
    key: '1',
    sorter: (a, b) => {
      const valA = String(a.使用者ID);
      const valB = String(b.使用者ID);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '使用者名稱',
    dataIndex: '使用者名稱',
    key: '2',
    sorter: (a, b) => {
      const valA = String(a.使用者名稱);
      const valB = String(b.使用者名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '受影響的物件1',
    dataIndex: '受影響的物件1',
    key: '3',
    sorter: (a, b) => {
      const valA = String(a.受影響的物件1);
      const valB = String(b.受影響的物件1);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '受影響的物件2',
    dataIndex: '受影響的物件2',
    key: '4',
    sorter: (a, b) => {
      const valA = String(a.受影響的物件2);
      const valB = String(b.受影響的物件2);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '名稱',
    dataIndex: '名稱',
    key: '5',
    sorter: (a, b) => {
      const valA = String(a.名稱);
      const valB = String(b.名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '日期',
    dataIndex: '日期',
    key: '6',
    sorter: (a, b) => {
      const valA = String(a.日期);
      const valB = String(b.日期);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '活動',
    dataIndex: '活動',
    key: '7',
    sorter: (a, b) => {
      const valA = String(a.活動);
      const valB = String(b.活動);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '物件',
    dataIndex: '物件',
    key: '8',
    sorter: (a, b) => {
      const valA = String(a.物件);
      const valB = String(b.物件);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '物件ID',
    dataIndex: '物件ID',
    key: '9',
    sorter: (a, b) => {
      const valA = String(a.物件ID);
      const valB = String(b.物件ID);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '種類',
    dataIndex: '種類',
    key: '10',
    sorter: (a, b) => {
      const valA = String(a.種類);
      const valB = String(b.種類);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  
];


//過濾鈕和日期鈕設定
const defaultCheckedList = (value) => {
  console.log(`selected ${value}`);
};


function Audit() {
  
  //過濾鈕和日期鈕設定
  const [openFilter, setOpenFilter] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const handleOpenChangeFilter = (newOpen) => {
    setOpenFilter(newOpen);
  };

  const handleOpenChangeDate = (newOpen) => {
    setOpenDate(newOpen);
  };

  const hideFilter = () => {
    setOpenFilter(false);
  };

  const hideDate = () => {
    setOpenDate(false);
  };


//匯出按鈕彈跳視窗內容設定
const [modalOutlined, setmodalOutlined] = useState(false);


//下拉式選單，表格欄位篩選設定

  const defaultCheckedList = columns.map((item) => item.key); // 預選欄位，填入想預選的欄位 keys

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const optionsColumns = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !(Array.isArray(checkedList) && checkedList.includes(item.key)),
  }));


//過濾鍵裡的選項設定
  const [tabPosition, setTabPosition] = useState('left');
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);};



//日期鍵裡時間範圍的選項設定
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isRangeOpen, setRangeOpen] = useState(false);

  const handleSelectRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const quickSelect = (months) => {
    const now = new Date();
    setStartDate(addMonths(now, -months));
    setEndDate(now);}


//表格分頁設定
const [currentPage, setCurrentPage] = useState(1); // 當前頁面
const [pageSize, setPageSize] = useState(10); // 每頁顯示的項目數
const totalData = 100; // 假設的數據總數
const totalPage = Math.ceil(totalData / pageSize); // 總頁數

const [drawerVisible, setDrawerVisible] = useState(false);
const [selectedRecord, setSelectedRecord] = useState(null); // 用於儲存選中的行數據


  // 模擬表格中的數據
  const Tabledata = [
    {
      key: '1',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '2',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '3',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '4',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '5',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '6',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '7',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '8',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '9',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '10',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '11',
      使用者ID: '123',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '12',
      使用者ID: '456',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },
    {
      key: '13',
      使用者ID: '456',
      使用者名稱: 32,
      受影響的物件1: '12346k',
      受影響的物件2: '123',
      名稱: 32,
      日期: '12346k',
      活動: '12346k',
      物件: '123',
      物件ID: 32,
      種類: '12346k',
    },


  ];

// 定義每頁顯示的數據
const paginatedData = Tabledata.slice((currentPage - 1) * pageSize, currentPage * pageSize);

// 分頁切換處理函數
const handlePageChange = (page, pageSize) => {
  setCurrentPage(page);
  setPageSize(pageSize);
};


  // 打開 Drawer 的函數
  const showDrawer = (record) => {
    setSelectedRecord(record);
    setDrawerVisible(true);
  };

  // 關閉 Drawer 的函數
  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedRecord(null);
  };

//表格的下拉式選單設定
const [selectedItems, setSelectedItems] = useState(defaultCheckedList);

    
///////
  return (
    <Layout>
    
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        {/* 最左上角的 "審核追蹤"  */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">審核追蹤</Text>
        </div>
      </header>
    </div>
    <br/>
    <br/> 
    <div style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical">
      <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
      &nbsp;&nbsp;
      <>
        <Popover  //過濾內容設定
           overlayStyle={{ width: '512px', height: '420px' }}
          placement="bottom"
          content={
          <div>
            <Tabs 
              tabPosition={tabPosition}
              items={itemsFilter} // 直接使用預定義的 items
            />
            <div>
              <Button onClick={hideFilter}>清除</Button>
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>
              &nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
            </div>
          </div>
          }
          title="過濾"       
          trigger="click"
          open={openFilter}
          onOpenChange={handleOpenChangeFilter}
        >
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}} ><FilterOutlined />過濾</Button>
        </Popover>

        &nbsp;&nbsp;

        <Popover //日期內容設定
          placement="bottom"
          content={
          <div>

            <Flex vertical gap="middle">
                <Radio.Group
                  block
                  options={options3}
                  defaultValue="已新增日期"
                  optionType="button"
                  buttonStyle="solid"
                />
            </Flex>
            <br/>
            <div className="button-group">
              <button onClick={() => quickSelect(0)}>今天</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(1)}>上個月</button>&nbsp;&nbsp;
              <button onClick={() => quickSelect(3)}>過去三個月</button>&nbsp;&nbsp;
              <button onClick={() => setRangeOpen(!isRangeOpen)}>自訂</button>
            </div>
            <br/>
            <div className="date-display">
              {startDate && endDate
                ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                : '請選擇日期'}
            </div>
      
            {isRangeOpen && (
              <div style={{ display: 'flex' }}>
                <DatePicker
                  selected={startDate}
                  onChange={handleSelectRange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}  // 顯示兩個月曆
                  inline
                />
              </div>
            )}
            <br/>
            <div className="action-buttons">
              <Button onClick={() => { setStartDate(null); setEndDate(null); }}>清除</Button>&nbsp;&nbsp;
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>&nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
              {/* <button onClick={() => onApply(startDate, endDate)}>套用</button> */}
            </div>

          </div>
          }
          title="選取日期"
          trigger="click"
          open={openDate}
          onOpenChange={handleOpenChangeDate}
        >        
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}}><CalendarOutlined />日期</Button>
        </Popover>
      </>
      &nbsp;&nbsp;
      <Text style={{fontSize:'28px' ,color:'lightgray'}}>|</Text>
      <Button style={{height:'32px', width:'86.17px',  margin:'5px 0 0 0' ,border:'none',backgroundColor:'transparent', boxShadow: 'none' }}>
          全部清除
      </Button>
    </div>

    <div>
      
      <div
        style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical"
      >
        {/* 左側的總計和匯入按鈕 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text2">總計: 2</Text>
          &nbsp;&nbsp;&nbsp;
          <Button
            style={{
              height: '32px',
              width: '86.17px',
              margin: '5px 0 0 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
            onClick={() => setmodalOutlined(true)}
          >
            <UploadOutlined />
            匯出
          </Button>
        </div>

        {/* 右側的下拉式選單(表格的) */}
        <div style={{ position: 'relative', width: '15%' }}>
           {/* 始終顯示的 placeholder */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            padding: '8px 0px 8px 11px',
            color: selectedItems.length === 0 ? '#272727' : '#272727',
            pointerEvents: 'none',
            zIndex: 1,
          }}
        >
          欄
        </div>
          <Select
            defaultValue={defaultCheckedList} // 設置預選的欄位 keys
            mode="multiple"
            allowClear
            style={{
              width: '100%',
            }}
            placeholder="選取直欄"
            options={optionsColumns}
            onChange={(value) => {
              setCheckedList(value); // 更新選中的值
            }}
            tagRender={() => null} // 隱藏標籤
            dropdownRender={(menu) => <div>{menu}</div>} // 自定義下拉選單
            showSearch={false} // 禁用搜尋功能，禁止使用者輸入
          />
        </div>
        {/* 匯出按鈕的彈出窗口內容 */}
        <Modal
          title="匯出資料"
          centered
          open={modalOutlined}
          onOk={() => setmodalOutlined(false)}
          onCancel={() => setmodalOutlined(false)}
          okText="匯出" // 定義確認按鈕的文字
          cancelText="取消" // 定義取消按鈕的文字
        >
          <hr/>
          <p>另存新檔</p>
          <Input placeholder='預設的檔名'></Input>
          <br/>
          <br/>
          <p>格式</p>
          <Radio.Group name="radiogroup" defaultValue={1}>
            <Radio value={1}>CSV 檔案</Radio>
            <br/>
            <br/>
            <Radio value={2}>JSON 檔案</Radio>
          </Radio.Group>
          <br/>
          <br/>
          <p>項目數: 2</p>
          <Alert message="所有目前顯示的項目都會匯出。" type="info" showIcon />
          <br/>
          <hr/>
        </Modal>
      </div>



      <div style={{ margin: '20px 50px' }}>
        {/* 表格部分 */}
        <Table
          columns={newColumns}
          dataSource={paginatedData}
          pagination={false} // 禁用自動分頁
          onRow={(record) => ({
            onClick: () => showDrawer(record), // 點擊行時打開 Drawer
          })}
          rowClassName="clickable-row" // 增加行的點擊效果
          locale={{
            triggerAsc: '點擊以由小到大排序',
            triggerDesc: '點擊以以由大到小排序',
            cancelSort: '取消排序',
          }}
        />

        {/* 自定義分頁 */}
        <div className="pagination">
          <Pagination
            current={currentPage}
            total={totalData}
            pageSize={pageSize}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `總共 ${total} 個項目數`}
            onChange={handlePageChange}
          />
        </div>
        
        {/* Drawer 組件 */}
        <Drawer
          title={selectedRecord ? selectedRecord.name : ''}
          placement="right"
          onClose={closeDrawer}
          visible={drawerVisible}
          width={650}
        >
          {selectedRecord && (
            <div>
              <Text code>登入</Text>
              <br/>
              <br/>
              <p style={{fontSize:'24px'}}>組織: AppScan Trial Organization{selectedRecord.age}</p>
              <hr/>
              <br/>
              <p style={{fontSize:'14.08px', background:'#F0F0F0', padding: '8px 0 8px 8px'}}>使用者 {selectedRecord.description}</p>
              <p style={{padding: '2px 0 8px 8px'}}>使用者名稱:</p> 
              <p style={{padding: '2px 0 8px 8px'}}>使用者 ID:</p>
              <p style={{padding: '2px 0 8px 8px'}}>E-mail:</p>
              <br/>
              <p style={{fontSize:'14.08px', background:'#F0F0F0', padding: '8px 0 8px 8px'}}>動作 {selectedRecord.description}</p>
              <p style={{padding: '2px 0 8px 8px'}}>活動:</p> 
              <p style={{padding: '2px 0 8px 8px'}}>Object type:</p>
              <p style={{padding: '2px 0 8px 8px'}}>Object name:</p>
              <p style={{padding: '2px 0 8px 8px'}}>日期:</p>
              <br/>
              <p style={{fontSize:'16px'}}><strong>Configuration</strong></p>
              <br/>
              <p style={{fontSize:'14.08px', background:'#F0F0F0', padding: '8px 0 8px 8px'}}>新資料 {selectedRecord.description}</p>
              <p style={{padding: '2px 0 8px 8px'}}>類型:</p>


            </div>
          )}
        </Drawer>
      </div>

        
    </div>

    </Layout>
  );
}

export default Audit;
