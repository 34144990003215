// pages/About.js
import React from 'react';
import Layout from '../Layout';

import '../App.css';
import {  Typography, Button, Popover, Space, Input, Flex, Tabs  } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const content = (
  <div>
    <p>在此頁面中，您可以查看應用程式中的所有掃描</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/r_scan_status.html" target="blank">進一步瞭解</a>
  </div>
);

const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: '1',
    label: 'DAST (0)',
    children: 
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // 設置高度為整個視窗高度
        flexDirection: 'column', // 垂直排列元素
        textAlign: 'center' // 中心對齊文字
    }}>
      <img
        alt="Visual Studio 2022"
        src="/image/Scan.svg"
        style={{ width: '192px', height: '192px' }}  // 固定寬100px, 高100px
      />
      <h4>尚無掃描</h4>
      <Text style={{ fontSize: '15px' }}>掃描項目由應用程式視圖建立。</Text>
    </div>
  },
  {
    key: '2',
    label: 'SAST (0)',
    children: 
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // 設置高度為整個視窗高度
        flexDirection: 'column', // 垂直排列元素
        textAlign: 'center' // 中心對齊文字
    }}>
      <img
        alt="Visual Studio 2022"
        src="/image/Scan.svg"
        style={{ width: '192px', height: '192px' }}  // 固定寬100px, 高100px
      />
      <h4>尚無掃描</h4>
      <Text style={{ fontSize: '15px' }}>掃描項目由應用程式視圖建立。</Text>
    </div>
  },
  {
    key: '3',
    label: 'SCA (0)',
    children: 
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // 設置高度為整個視窗高度
        flexDirection: 'column', // 垂直排列元素
        textAlign: 'center' // 中心對齊文字
    }}>
      <img
        alt="Visual Studio 2022"
        src="/image/Scan.svg"
        style={{ width: '192px', height: '192px' }}  // 固定寬100px, 高100px
      />
      <h4>尚無掃描</h4>
      <Text style={{ fontSize: '15px' }}>掃描項目由應用程式視圖建立。</Text>
    </div>
  },
  {
    key: '4',
    label: 'IAST (0)',
    children: 
        <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', // 設置高度為整個視窗高度
        flexDirection: 'column', // 垂直排列元素
        textAlign: 'center' // 中心對齊文字
    }}>
      <img
        alt="Visual Studio 2022"
        src="/image/Scan.svg"
        style={{ width: '192px', height: '192px' }}  // 固定寬100px, 高100px
      />
      <h4>尚無 IAST 階段作業</h4>
      <Text style={{ fontSize: '15px' }}>掃描項目由應用程式視圖建立。</Text>
    </div>
  },
];

function Scan() {
  return (
    <Layout>
      <div style={{margin:'10px 0 0 10px'}} >
        <header>
          <Text  className="custom-text">掃描及階段作業</Text> 
          <Space wrap>
            <Popover content={content} title="掃描及階段作業" trigger="click">
              <Button 
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />} 
                shape="circle" 
                style={{
                  backgroundColor: 'transparent', // 調整按鈕的背景色為灰白色
                  border: 'transparent', // 邊框顏色
                  boxShadow: 'none' // 去除陰影
                }}
              />
            </Popover>
          </Space>
        </header>

        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

      </div>
    </Layout>
  );
}

export default Scan;
