// pages/About.js
import React, { useEffect, useState } from 'react';
import Layout from '../Layout';

import '../App.css';
import {  Typography, Button, Popover, Space, Input, Flex, Anchor,Card, Select,Divider, Radio, Col, Row  } from 'antd';
import { QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';



const { Text } = Typography;

const { Meta } = Card;

const options = [
  {
    label: 'Windows',
    value: 'Windows',
  },
  {
    label: 'Linux',
    value: 'Linux',
  },
  {
    label: 'Mac',
    value: 'Mac',
  },
];

const labelRender = (props) => {
  const { label, value } = props;
  if (label) {
    return value;
  }
}



function Plugins() {

      // 定義滾動到頁面頂部的函數
      const scrollToTop = () => {
        window.scrollTo({ top: 12, behavior: 'smooth' });
      };
  
      const [size, setSize] = useState('large'); // default is 'middle'

  return (
   

 <Layout>
  <header style={{ 
    margin: '40px 20px', 
    position: 'fixed',  // 固定位置
    top: 8,             // 距離頂端 0px
    width: '100%',      // 寬度設為 100%，保持全頁寬
    zIndex: 1000,       // 設定較高的 zIndex，確保在其他內容之上
    backgroundColor: '#f5f5f5', // 添加背景顏色，避免滾動時遮蓋其他內容
    
}}>
    <Text className="custom-text" style={{margin: '40px 20px'}} >整合</Text>

    <div style={{ 
      display: 'flex', 
      // justifyContent: 'center', 
      alignItems: 'center', 
      margin: '20px 320px'
    }}>
      <Button   onClick={() => {
        const target = document.getElementById('targetLabel');
        if (target) {
          window.scrollTo({
            top: target.getBoundingClientRect().top + window.scrollY - 300, // 調整數值以確保目標在 header 下方
            behavior: 'smooth',
          });
        }
      }}
      style={{ padding: '10px 20px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>IDE (10)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel2').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>CI/CD (7)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel3').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>問題報告追蹤系統 (4)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel4').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>漏洞管理 (3)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel5').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent'}}>AppScan 用戶端工具 (6)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel6').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>建立您自己的整合 (2)</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button onClick={() => document.getElementById('targetLabel7').scrollIntoView({ behavior: 'smooth' })} style={{ padding: '6px 10px', borderRadius: '6px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#AAAAAA',backgroundColor: 'transparent' }}>其他 (2)</Button>
    </div>
    <br/>
    <hr/>
  </header>

  <h2 id="targetLabel" style={{ margin: '160px 0 20px 320px', fontSize: '24px', fontWeight: 480, scrollMarginTop: '800px' }} >IDE (10)</h2>

  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.appscan-vs" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Visual Studio 2022"
                        src="/image/Visual_Studio_2022.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Visual Studio 2022" />
                  <br/>
                
                  <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.appscan-vs" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.hclappscancodesweep" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Visual Studio Code"
                        src="/image/Visual_Studio_Code.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Visual Studio Code"/>
                  <br/>
                
                  <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.hclappscancodesweep" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="IntelliJ IDEA"
                        src="/image/IntelliJ_IDEA.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="IntelliJ IDEA" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="PyCharm"
                        src="/image/PyCharm.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="PyCharm" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

        </div>

        <br/>

        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="WebStorm"
                        src="/image/WebStorm.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="WebStorm" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="PhpStorm"
                        src="/image/PhpStorm.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="PhpStorm" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Rider"
                        src="/image/Rider.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Rider" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="CLion"
                        src="/image/CLion.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="CLion" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>

        <br/>

        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="GoLand"
                        src="/image/GoLand.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="GoLand" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="RubyMine"
                        src="/image/RubyMine.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="RubyMine" />
                  <br/>
                
                  <a href="https://plugins.jetbrains.com/plugin/10881-hcl-appscan" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>
  <br/>   

  <h2 id="targetLabel2" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >CI/CD (7)</h2>
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://github.com/dwwatk02/appscan_asoc_links/blob/main/README.md" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Community plugins"
                        src="/image/Community_plugins.svg"
                        style={{ width: '150px', height: '150px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Community plugins" />
                  <br/>
                
                  <a href="https://github.com/dwwatk02/appscan_asoc_links/blob/main/README.md" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://github.com/marketplace/actions/hcl-appscan-codesweep" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="GitHub"
                        src="/image/GitHub.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="GitHub" />
                  <br/>
                
                  <a href="https://github.com/marketplace/actions/hcl-appscan-codesweep" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-maven-plugin" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Maven"
                        src="/image/Maven.svg"
                        style={{ width: '150px', height: '50px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Maven" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-maven-plugin" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-gradle-plugin" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Gradle"
                        src="/image/Gradle.svg"
                        style={{ width: '120px', height: '120px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Gradle" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-gradle-plugin" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

        </div>

        <br/>

        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://plugins.jenkins.io/appscan/" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Jenkins"
                        src="/image/Jenkins.svg"
                        style={{ width: '80px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Jenkins" />
                  <br/>
                
                  <a href="https://plugins.jenkins.io/appscan/" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.ApplicationSecurity-VSTS" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Azure Devops"
                        src="/image/Azure_Devops.svg"
                        style={{ width: '180px', height: '180px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Azure Devops" />
                  <br/>
                
                  <a href="https://marketplace.visualstudio.com/items?itemName=HCLTechnologies.ApplicationSecurity-VSTS" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-cloud-cli/blob/main/Integrating%20HCL%20AppScan%20on%20Cloud%20with%20AWS%20CodeBuild%20and%20CodePipeline.md" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AWS"
                        src="/image/AWS.svg"
                        style={{ width: '100px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AWS" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-cloud-cli/blob/main/Integrating%20HCL%20AppScan%20on%20Cloud%20with%20AWS%20CodeBuild%20and%20CodePipeline.md" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>
        </div>

  <br/>
  <h2 id="targetLabel3" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >問題報告追蹤系統 (4)</h2>
  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Azure Devops"
                        src="/image/Azure_Devops.svg"
                        style={{ width: '180px', height: '180px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Azure Devops" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Jira"
                        src="/image/Jira.svg"
                        style={{ width: '100px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Jira" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://marketplace.atlassian.com/apps/1234151/hcl-appscan-integration-for-jira-cloud?hosting=cloud&tab=overview" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Jira Cloud"
                        src="/image/Jira_Cloud.svg"
                        style={{ width: '100px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="Jira Cloud" />
                  <br/>
                
                  <a href="https://marketplace.atlassian.com/apps/1234151/hcl-appscan-integration-for-jira-cloud?hosting=cloud&tab=overview" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="RTC"
                        src="/image/RTC.svg"
                        style={{ width: '100px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="RTC" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-issue-gateway" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>

  <br/>
  <h2 id="targetLabel4" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >漏洞管理 (3)</h2>
  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://store.servicenow.com/sn_appstore_store.do#!/store/application/64fe94011bb79d10fc4a777e0a4bcbb1" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="ServiceNow"
                        src="/image/ServiceNow.svg"
                        style={{ width: '160px', height: '100px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="ServiceNow" />
                  <br/>
                
                  <a href="https://store.servicenow.com/sn_appstore_store.do#!/store/application/64fe94011bb79d10fc4a777e0a4bcbb1" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://coalfire.com/services/security" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="ThreadFix"
                        src="/image/ThreadFix.svg"
                        style={{ width: '140px', height: '132px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="ThreadFix" />
                  <br/>
                
                  <a href="https://coalfire.com/services/security" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

              <a href="https://www.blackduck.com/integrations.html" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="CodeDX"
                        src="/image/CodeDX.svg"
                        style={{ width: '150px', height: '75px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="CodeDX" />
                  <br/>
                
                  <a href="https://www.blackduck.com/integrations.html" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>

  <br/>
  <h2 id="targetLabel5" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >AppScan 用戶端工具 (6)</h2>
  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://help.hcl-software.com/appscan/ASoC/asd_AppScanStandard.html" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Standard"
                        src="/image/AppScan_Standard.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Standard" />
                  <br/>
                
                  <a href="https://help.hcl-software.com/appscan/ASoC/asd_AppScanStandard.html" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

            
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Go!"
                        src="/image/AppScan_Go.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Go!" />
                  <br/>

                  <Select
                    labelRender={labelRender}
                    defaultValue="Windows"
                    style={{
                      width: '76%',
                    }}
                    options={options}
                  />
                  

                  <Button type="default"   icon={<DownloadOutlined />} size={size} style={{
                    marginLeft: '8px', width: '32px',  // 自定義寬度
                    height: '32px',  // 自定義高度
                    
                    }}/>
                                
                  <a href="https://help.hcl-software.com/appscan/ASoC/src_irx_gen_gui.html" target="blank" rel="noopener noreferrer">   
                      參閱說明文件
                  </a> 
                  
                </Card>
              

            
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="SAST Client Util / CLI"
                        src="/image/SAST_Client_Util_CLI.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="SAST Client Util / CLI" />
                  <br/>

                  <Select
                    labelRender={labelRender}
                    defaultValue="Windows"
                    style={{
                      width: '76%',
                    }}
                    options={options}
                  />
                  

                  <Button type="default"   icon={<DownloadOutlined />} size={size} style={{
                    marginLeft: '8px', width: '32px',  // 自定義寬度
                    height: '32px',  // 自定義高度
                    
                    }}/>
                                
                  <a href="https://help.hcl-software.com/appscan/ASoC/src_cli_win.html" target="blank" rel="noopener noreferrer">   
                      參閱說明文件
                  </a> 
                  
                </Card>

              <a href="https://chromewebstore.google.com/detail/appscan-activity-recorder/ijbfdoojnepibegmkhhilmeijonibmcn?hl=en" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Activity Recorder"
                        src="/image/AppScan_Activity_Recorder.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Activity Recorder" />
                  <br/>
                
                  <a href="https://chromewebstore.google.com/detail/appscan-activity-recorder/ijbfdoojnepibegmkhhilmeijonibmcn?hl=en" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>

        </div>

        <br/>

        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
        <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Traffic Recorder"
                        src="/image/AppScan_Traffic_Recorder.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Traffic Recorder" />
                  <br/>

                  <Select
                    labelRender={labelRender}
                    defaultValue="Windows"
                    style={{
                      width: '76%',
                    }}
                    options={options}
                  />
                  

                  <Button type="default"   icon={<DownloadOutlined />} size={size} style={{
                    marginLeft: '8px', width: '32px',  // 自定義寬度
                    height: '32px',  // 自定義高度
                    
                    }}/>
                                
                  <a href="https://help.hcl-software.com/appscan/ASoC/TrafficRecorder.html" target="blank" rel="noopener noreferrer">   
                      參閱說明文件
                  </a> 
                  
                </Card>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-cloud-cli" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Cloud CLI"
                        src="/image/AppScan_Cloud_CLI.svg"
                        style={{ width: '100px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Cloud CLI" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-cloud-cli" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>
  <br/>
  <h2  id="targetLabel6" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >建立您自己的整合 (2)</h2>
  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://cloud.appscan.com/swagger/index.html" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan Swagger"
                        src="/image/AppScan_Swagger.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan Swagger" />
                  <br/>
                
                  <a href="https://cloud.appscan.com/swagger/index.html" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-sdk" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="AppScan SDK"
                        src="/image/AppScan SDK.svg"
                        style={{ width: '90px', height: '90px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="AppScan SDK" />
                  <br/>
                
                  <a href="https://github.com/HCL-TECH-SOFTWARE/appscan-sdk" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div> 

  <br/>
  <h2 id="targetLabel7" style={{ margin: '20px 320px', fontSize: '24px', fontWeight: 480 ,marginTop: '100px', scrollMarginTop: '210px' }} >其他 (2)</h2>
  <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap',margin: '20px 320px' }}>
              <a href="https://help.securityjourney.com/en/articles/4624470-hcl-appscan-integration" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="HackEDU"
                        src="/image/HackEDU.svg"
                        style={{ width: '150px', height: '150px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="HackEDU" />
                  <br/>
                
                  <a href="https://help.securityjourney.com/en/articles/4624470-hcl-appscan-integration" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                </Card>
              </a>

              <a href="https://docs.sdelements.com/release/latest/guide/docs/integrations/security_tools/supported_tools/" target="blank" rel="noopener noreferrer">
                <Card 
                  hoverable
                  style={{
                    width: 260,
                    height: 282,
                  }}
                  cover={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px', // 設置一個合適的高度來放置圖片
                      }}
                    >
                      <img
                        alt="Visual Studio 2022"
                        src="/image/SD Elements.svg"
                        style={{ width: '92px', height: '92px' }}  // 固定寬100px, 高100px
                      />
                    </div>
                  }
                >
                  <Meta title="SD Elements" />
                  <br/>
                
                  <a href="https://docs.sdelements.com/release/latest/guide/docs/integrations/security_tools/supported_tools/" target="blank" rel="noopener noreferrer">   
                      檢視並安裝
                  </a> 
                  
                </Card>
              </a>
        </div>

  



</Layout>
  

  );
}

export default Plugins;
