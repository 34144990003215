// pages/Home.js
import '../App.css';
import React, { useState } from 'react';
import { Checkbox, Divider, Table, Select, Space, Typography, Button, Popover, Modal,Input,Radio, Tabs, Flex,DatePicker, ConfigProvider,Row, Col,Pagination,message, Upload, Steps,Drawer   } from 'antd';
import { QuestionCircleOutlined,SearchOutlined,DownloadOutlined,FilterOutlined,InboxOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import Layout from '../Layout';
// import 'node_modules/antd/dist/antd.css';

const { Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

const { TabPane } = Tabs;



//原則標題旁邊小問號圈圈對話框裡的內容
const contentAppPolices = (
    <div style={{
      width: '400px',          // 設置固定寬度
      height: '120px',         // 設置固定高度
      overflowY: 'auto',       // 當內容超過高度時顯示垂直滾動條
      wordWrap: 'break-word',  // 長字換行處理，防止文字溢出
    }}>
    <p>原則是過濾掃描中所發現問題的方式，因此您只會看到與您相關的問題。您可以建立自己的原則，或使用預先定義的原則。您最多可以將五個原則與應用程式建立關聯。</p>
    <a href="https://help.hcl-software.com/appscan/ASoC/appseccloud_policy.html" target="_blank">進一步瞭解</a>
  </div>
);

//大標題下方 基準原則位於何處 的內容
const contentAppPolices2 = (
  <div style={{
    width: '420px',          // 設置固定寬度
    height: '220px',         // 設置固定高度
    overflowY: 'auto',       // 當內容超過高度時顯示垂直滾動條
    wordWrap: 'break-word',  // 長字換行處理，防止文字溢出
  }}>
    <p>不同於其他原則，基準線原則是應用程式特有的原則，因此不在這個頁面上。基準線原則會根據應用程式在設定日期之後第一次找到的問題計算相符性。</p>
    <p>若要設定應用程式的基準線原則，請執行下列動作：</p>
    <Text>1.在「應用程式」頁面上，按一下要開啟的應用程式。</Text>
    <br/>
    <Text>2. 按一下「配置」圖示>「管理原則」。</Text>
    <br/>
    <Text>3. 按一下「新增基準線原則」或「更新基準線原則」。</Text>
    <br/>
    <Text>4. 視需要調整日期及時間，然後按一下「設定基準線」。</Text>
  </div>
);


//表格的每一列的Drawer的 與應用程式建立關聯 的 選取應用程式 旁邊的問號內容
const contentAppPolices3 = (
  <div style={{
    width: '420px',          // 設置固定寬度
    height: '80px',         // 設置固定高度
    overflowY: 'auto',       // 當內容超過高度時顯示垂直滾動條
    wordWrap: 'break-word',  // 長字換行處理，防止文字溢出
  }}>
    <p>一旦您將原則與應用程式建立關聯，我們就可以測試應用程式是否與其相符。如果應用程式以停用狀態出現在清單中，原因是它已有 5 個相關聯原則。</p>
  </div>
);



//過濾鈕設定
const defaultCheckedList = (value) => {
  console.log(`selected ${value}`);
};

//過濾按鈕對話框得內容
const itemsFilter = [
  {
    label: '原則類型',
    key: '1',
    children:(
      <div>
        <p>原則類型</p>
        <Checkbox>業界標準</Checkbox><br/><br/>
        <Checkbox>法規和規性</Checkbox><br/><br/>
        <Checkbox>自訂</Checkbox><br/><br/>
      </div>
    )
  },
  {
    label: '相關聯的應用程式',
    key: '2',
    children: (
      <div>
        <p>相關聯的應用程式</p>
        <Checkbox>已與應用程式相關聯</Checkbox><br/><br/>
        <Checkbox>未與應用程式相關聯</Checkbox><br/><br/>
      </div>
    )
  },
];


//Table表格欄位名稱設定
const columns = [
  {
    title: '原則名稱',
    dataIndex: '原則名稱',
    key: '1',
    sorter: (a, b) => {
      const valA = String(a.原則名稱);
      const valB = String(b.原則名稱);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '原則類型',
    dataIndex: '原則類型',
    key: '2',
    sorter: (a, b) => {
      const valA = String(a.原則類型);
      const valB = String(b.原則類型);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '前次更新',
    dataIndex: '前次更新',
    key: '3',
    sorter: (a, b) => {
      const valA = String(a.前次更新);
      const valB = String(b.前次更新);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '新增者',
    dataIndex: '新增者',
    key: '4',
    sorter: (a, b) => {
      const valA = String(a.新增者);
      const valB = String(b.新增者);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },
  {
    title: '相關聯的應用程式',
    dataIndex: '相關聯的應用程式',
    key: '5',
    sorter: (a, b) => {
      const valA = String(a.相關聯的應用程式);
      const valB = String(b.相關聯的應用程式);
      return valA.localeCompare(valB, undefined, { numeric: true, sensitivity: 'base' });
    },
  },

];


//Table表格每一列Drawer中表格欄位的設定
const columns2 = [
  {
    title: '應用程式名稱',
    dataIndex: '應用程式名稱',
    key: '1',
  },
  {
    title: '資產群組',
    dataIndex: '資產群組',
    key: '2',
  },

];


//Table表格每一列Drawer中 相關聯的應用程式 的 依資產群組過濾應用程式 設定
const handleChange = (value) => {
  console.log(`selected ${value}`);
};



function Policies() {
  
  //過濾鈕和日期鈕設定
  const [openFilter, setOpenFilter] = useState(false);


  const handleOpenChangeFilter = (newOpen) => {
    setOpenFilter(newOpen);
  };


  const hideFilter = () => {
    setOpenFilter(false);
  };



//下拉式選單，表格欄位篩選設定
  // const [checkedList, setCheckedList] = useState(defaultCheckedList);
  // const optionsColumns = columns.map(({ key, title }) => ({
  //   label: title,
  //   value: key,
  // }));

  // const newColumns = columns.map((item) => ({
  //   ...item,
  //   hidden: !(Array.isArray(checkedList) && checkedList.includes(item.key)),
  // }));


//過濾鍵裡的選項設定
  const [tabPosition, setTabPosition] = useState('left');
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);};

////

////

//頁面資料數量控制
const [currentPage, setCurrentPage] = useState(1); // 當前頁面
const [pageSize, setPageSize] = useState(10); // 每頁顯示的項目數
const totalData = 100; // 假設的數據總數
const totalPage = Math.ceil(totalData / pageSize); // 總頁數

const [drawerVisible, setDrawerVisible] = useState(false);
const [selectedRecord, setSelectedRecord] = useState(null); // 用於儲存選中的行數據

  // 模擬表格中的數據
  const Tabledata = [
    {
      key: '1',
      原則名稱: '123',
      原則類型: 32,
      前次更新: '12346k',
      新增者: '123',
      相關聯的應用程式: 32,
    },
    {
      key: '2',
      原則名稱: '123',
      原則類型: 32,
      前次更新: '12346k',
      新增者: '123',
      相關聯的應用程式: 32,
    },
    {
      key: '3',
      原則名稱: '123',
      原則類型: 32,
      前次更新: '12346k',
      新增者: '123',
      相關聯的應用程式: 32,
    },

  ];

  // 分頁處理數據
  const paginatedData = Tabledata.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  // 分頁切換處理函數
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
      setPageSize(pageSize);
  };

    // 打開 Drawer 的函數
    const showDrawer = (record) => {
      setSelectedRecord(record);
      setDrawerVisible(true);
    };
  
    // 關閉 Drawer 的函數
    const closeDrawer = () => {
      setDrawerVisible(false);
      setSelectedRecord(null);
    };


//Drawer裡相關聯的應用程式的分頁設定
const [Tab1currentPage, setTab1currentPage] = useState(1);

    
///////
  return (
    <Layout>
    
    {/* {最左上角大標題區塊} */}
    <div>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', // 保持垂直居中對齊
          padding: '30px 50px', // 根據需要調整 padding
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text">原則</Text>
          &nbsp;&nbsp;&nbsp; {/* 添加間距，根據需要調整 */}
          <Space wrap> 
            <Popover placement="bottom" content={contentAppPolices} title="原則" trigger="click">
              <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                shape="circle"
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                }}
              />
            </Popover>
          </Space>
        </div>
      </header>
      <div style={{ width: '100%',
          padding: '0 32px',
          display: 'flex',
          alignItems: 'center' }}>
        <Space wrap> 
            <Popover placement="bottom" content={contentAppPolices2} title="基準原則位於何處" trigger="click">
              <Button
                style={{
                  backgroundColor: 'transparent',
                  border: 'transparent',
                  boxShadow: 'none',
                  textDecoration: 'underline dashed', // 增加虛線底線
                  textUnderlineOffset: '5px', // 控制底線與文字的間距
              }}>基準原則位於何處？
              </Button>
            </Popover>
        </Space>
      </div>
    </div>

    <br/>
    <br/> 

    {/* {搜尋、過濾、全部清除等相關設定} */}
    <div style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical">
      <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
      &nbsp;&nbsp;
      
        <Popover
           overlayStyle={{ width: '512px', height: '420px' }}
          placement="bottom"
          content={
          <div>
            <Tabs 
              tabPosition={tabPosition}
              items={itemsFilter} // 過濾按鈕裡的選項內容
            />
            <div>
              <Button onClick={hideFilter}>清除</Button>
              <Button style={{margin:'25px 0 8px 280px'}} onClick={hideFilter}>取消</Button>
              &nbsp;&nbsp;
              <Button onClick={hideFilter}>套用</Button>
            </div>
          </div>
          }
          title="過濾"       
          trigger="click"
          open={openFilter}
          onOpenChange={handleOpenChangeFilter}
        >
          <Button style={{border: '1px solid ',backgroundColor:'transparent', boxShadow:'none'}} ><FilterOutlined />過濾</Button>
        </Popover>

        &nbsp;&nbsp;


      
      &nbsp;&nbsp;
      <Text style={{fontSize:'28px' ,color:'lightgray'}}>|</Text>
      <Button style={{height:'32px', width:'86.17px',  margin:'5px 0 0 0' ,border:'none',backgroundColor:'transparent', boxShadow: 'none' }}>
          全部清除
      </Button>
    </div>



    <div>
      
      <div
        style={{
          width: '100%',
          padding: '0 50px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'  // 垂直居中對齊
        }}
        direction="vertical"
      >
        {/* 左側的總計 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text className="custom-text2">總計: 1</Text>
          &nbsp;&nbsp;&nbsp;
        </div>

        {/* 右側的下拉式選單
        <Select
          mode="multiple"
          allowClear
          style={{
            width: '15%',
          }}
          placeholder="選取直欄"
          onChange={defaultCheckedList}
          options={optionsColumns}
          onChange={(value) => { setCheckedList(value); }}
        /> */}
      </div>


    {/* 中間表格部分 */}
      <div style={{ margin: '20px 50px' }}>    
      <Table
        columns={columns}
        dataSource={paginatedData}
        pagination={false} // 禁用自動分頁
        onRow={(record) => ({
          onClick: () => showDrawer(record), // 點擊行時打開 Drawer
        })}
        rowClassName="clickable-row" // 增加行的點擊效果

        locale={{
          triggerAsc: '點擊以由小到大排序',
          triggerDesc: '點擊以由大到小排序',
          cancelSort: '取消排序',
        }}

      />

      {/* 自定義分頁設定 */}
      <div className="pagination">
        <Pagination
          current={currentPage}
          total={totalData}
          pageSize={pageSize}
          showSizeChanger
          showQuickJumper
          showTotal={(total) => `總共 ${total} 個項目數`}
          onChange={handlePageChange}
        />
      </div>

      {/* 表格中每一列Drawer 組件 */}
      <Drawer
          title={selectedRecord ? selectedRecord.name : ''}
          placement="right"
          onClose={closeDrawer}
          visible={drawerVisible}
          width={600}
      >
          {selectedRecord && (
            <div>
              <p style={{fontSize:'24px'}}>加拿大資訊自由與隱私權保護法規 (FIPPA) {selectedRecord.age}</p>
              {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
              <div style={{ padding: '16px' }}>
                <div style={{ clear: 'both' }}></div>

                <Tabs defaultActiveKey="1">
                  <TabPane key="1" tab="相關聯的應用程式">
                    {Tab1currentPage=== 1 ? (
                      <div>
                        <div>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center', // 保持垂直居中對齊
                            padding: '10px 0 0 0', // 根據需要調整 padding
                          }}>
                            <Text>總計: 0</Text>
                            <Input style={{height:'32px', width:'200px' }} placeholder="搜尋" prefix={<SearchOutlined />} />
                          </div>
                          <br/>
                          <div>
                            <Table
                              columns={columns2}
                              pagination={false} // 禁用自動分頁
                              rowClassName="clickable-row" // 增加行的點擊效果
                              style={{ border: '1px solid #E0E0E0' }} // 增加外框
                            />
                          </div>
                          <br/>
                          <hr/>
                          <br/>       
                        </div>
                        <Button style={{ float: 'right', height:'36px' }} onClick={() => setTab1currentPage(2)}>與應用程式建立關聯</Button>
                      </div>
                    ) : (
                      <div>
                        <Button style={{ border:'none', boxShadow:'none', padding:'0 10px 0 0' }} onClick={() => setTab1currentPage(1)}><ArrowLeftOutlined/>回上一頁</Button> 
                        <p style={{ padding:'16px 0 0 0' }}>依資產群組過濾應用程式</p>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: '100%',
                          }}
                          placeholder="所有資產群組(1)"
                          defaultValue={[]}
                          onChange={handleChange}
                          options={[
                            {
                              value: '1',
                              label: 'JWisdom',
                            },
                          ]}
                        />
                        <br/><br/>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                          <p style={{ padding:'16px 0 0 0' }}>選取應用程式</p>
                          <Popover placement="bottom" content={contentAppPolices3} title="為何部分應用程式已停用？" trigger="click">
                            <Button
                              icon={<QuestionCircleOutlined style={{ fontSize: '15px', color: '#000' }} />}
                              shape="circle"
                              style={{
                                backgroundColor: 'transparent',
                                border: 'transparent',
                                boxShadow: 'none',
                              }}
                            />
                          </Popover>
                        </div>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{
                            width: '100%',
                          }}
                          placeholder="選取或輸入"
                          defaultValue={[]}
                          onChange={handleChange}
                          options={[
                            {
                              value: '1',
                              label: 'DEMO-20240918',
                            },
                          ]}
                        />
                        <br/><br/>
                        <Popover placement="bottom" content={contentAppPolices3} title="為何部分應用程式已停用？" trigger="click">
                            <Button
                              shape="circle"
                              style={{
                                backgroundColor: 'transparent',
                                border: 'transparent',
                                boxShadow: 'none',
                                textDecoration: 'underline dashed', // 增加虛線底線
                                textUnderlineOffset: '5px', // 控制底線與文字的間距
                              }}
                            >為何部分應用程式已停用？</Button>
                        </Popover>
                        
                        <div style={{ borderTop: "1px solid #d9d9d9", marginTop: "20px" }} />
                        <br/>
                        <div style={{ float: 'right' }}>
                          <Button style={{ border:'none', boxShadow:'none', padding:'0 10px 0 0' }} onClick={() => setTab1currentPage(1)}>取消</Button> 
                          <Button >儲存</Button> 
                        </div>
                      </div>
                    )}
                  </TabPane>

                  <TabPane tab="程式碼" key="2">
                    <div>
                      <Text style={{ float: 'right', fontSize: '16px' }} copyable={{ text: 'text to be copied' }} />
                      <br/>
                      <br/>
                      <TextArea rows={4} />
                    </div>
                  </TabPane>

                  <TabPane tab="說明" key="3">
                    <div>
                      <Text style={{ float: 'right', fontSize: '16px' }} copyable={{ text: 'text to be copied' }} />
                      <br/>
                      <br/>
                      <TextArea rows={4} />
                    </div>
                  </TabPane>
              </Tabs>
              </div>
              
            </div>
          )}
      </Drawer>

    </div>
        
    </div>

    </Layout>
  );
}

export default Policies;
